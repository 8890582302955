export const getRandomInt = (min: number, max: number) => {
  const minCeil = Math.ceil(min);
  const maxFloor = Math.floor(max);
  return Math.floor(Math.random() * (maxFloor - minCeil + 1)) + minCeil;
};

export const getSignString = (num: number) => {
  const sign = Math.sign(num);

  if (sign === 0) {
    return '';
  }

  return sign === 1 ? '+' : '-';
};

export const round = (number: number, decimalPlaces: number = 2) => (
  Math.round((number + Number.EPSILON) * (10 ** decimalPlaces)) / (10 ** decimalPlaces)
);

export const isEven = (number: number) => (
  number % 2 === 0
);
