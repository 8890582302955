/* eslint-disable no-unused-expressions */
import { Container, Graphics } from 'pixi.js';
import { RoofDto, RoofPanelGridOrientation } from 'providers/api';
import getDimensions, {
  stageHeight, stageWidth, totalSolarPanelMarginX, totalSolarPanelMarginY
} from './getDimensions';

interface CreateWiresProps {
  solarPanelContainer: Container
  currentRoof: RoofDto

}

function createWires({ solarPanelContainer, currentRoof }: CreateWiresProps) {
  const {
    solarPanelArray,
    solarPanelHeight,
    panelBaseDimensionX,
    panelBaseDimensionY,
    scaleFactor,
    solarPanelWidth,
    numberOfPanelsAcross,
  } = getDimensions(currentRoof);
  const wireContainer = new Container();
  const individualWireContainer = new Container();

  const solarPanelWireWidth = solarPanelWidth + totalSolarPanelMarginX;
  const solarPanelWireHeight = 5;

  const wireDimensionX = currentRoof.panelOrientation === RoofPanelGridOrientation.Portrait
    ? solarPanelWireWidth
    : solarPanelWireHeight;
  const wireDimensionY = currentRoof.panelOrientation === RoofPanelGridOrientation.Portrait
    ? solarPanelWireHeight
    : solarPanelWireWidth;

  wireContainer.sortableChildren = true;

  solarPanelArray.forEach(
    (rowObject, rowIndex) => {
      const row = rowIndex; // row is the number of objects in the PanelGridArray
      rowObject.slots.forEach((panel, columnIndex) => {
        const column = columnIndex % (numberOfPanelsAcross);
        // e.g. 0000 1111 (4 solar panels on top row, 4 on bottom row).
        const uniquePanelId = `(${rowIndex}-${columnIndex})`;

        const setXPanelWithMargin = (column * panelBaseDimensionX) + (column * totalSolarPanelMarginX);
        const setYPanelWithMargin = (row * panelBaseDimensionY) + (row * totalSolarPanelMarginY);

        const solarPanelWire = new Graphics();
        solarPanelWire.zIndex = 1;
        solarPanelWire // 1
          .beginFill(0x000000)
          .drawRect(
          // column placement + gap between panels - center on panel
            setXPanelWithMargin - (currentRoof.panelOrientation === RoofPanelGridOrientation.Landscape
              ? -(solarPanelHeight / 3)
              : totalSolarPanelMarginX / 2),
            // row placement + gap between rows + Y position on solar panel
            setYPanelWithMargin + (currentRoof.panelOrientation === RoofPanelGridOrientation.Landscape
              ? -(totalSolarPanelMarginY / 2)
              : panelBaseDimensionY / 3),
            wireDimensionX,
            wireDimensionY,
          )
          .endFill();
        solarPanelWire // 2
          .beginFill(0x000000)
          .drawRect(
            setXPanelWithMargin - (
              currentRoof.panelOrientation === RoofPanelGridOrientation.Landscape
                ? -solarPanelWidth
                : totalSolarPanelMarginX / 2
            ),
            (row * panelBaseDimensionY)
            + (row * totalSolarPanelMarginY)
            + (solarPanelHeight - (
              currentRoof.panelOrientation === RoofPanelGridOrientation.Landscape
                ? solarPanelHeight + (totalSolarPanelMarginY / 2)
                : solarPanelHeight / 3)),
            wireDimensionX,
            wireDimensionY,
          )
          .endFill();
        solarPanelWire.name = `wire${uniquePanelId}`;

        individualWireContainer.addChild(solarPanelWire);
      });
    },
  );
  wireContainer.addChild(individualWireContainer);
  wireContainer.height = individualWireContainer.height * scaleFactor;
  wireContainer.width = individualWireContainer.width * scaleFactor;
  wireContainer.x = (stageWidth - solarPanelContainer.width) / 2;
  wireContainer.y = (stageHeight - solarPanelContainer.height) / 2;
  return { wireContainer, individualWireContainer };
}

export default createWires;
