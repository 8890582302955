import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ActionButtons from 'components/ActionButtons';
import { useFormik } from 'formik';
import { ConfigurationForm } from 'providers/api';
import React from 'react';
import { Nullable } from 'utils/types';
import { object, SchemaOf, string } from 'yup';

const postcodeRegex = /^([A-Z][A-HJ-Y]?\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/;

const retrieveConfigurationSchema: SchemaOf<ConfigurationForm> = object({
  email: string().email('Please enter a valid email').required('Please enter your email address'),
  postcode: string()
    .required('Please enter your postcode')
    .uppercase()
    .matches(
      postcodeRegex,
      'Please enter a valid UK postcode',
    ),
});

type IRetrieveConfigurationForm = Nullable<ConfigurationForm>
interface RetrieveConfigurationFormProps {
  onGetExistingConfigurationSubmit: (form: ConfigurationForm) => void
}

function RetrieveConfigurationForm({ onGetExistingConfigurationSubmit }: RetrieveConfigurationFormProps) {
  const {
    handleSubmit, handleChange, handleBlur, values, isValid, touched, errors,
  } = useFormik<IRetrieveConfigurationForm>({
    initialValues: {
      email: '',
      postcode: '',
    },
    onSubmit: (form, { setSubmitting }) => {
      setSubmitting(true);
      const retrieveConfiguration = retrieveConfigurationSchema.validateSync(form);
      onGetExistingConfigurationSubmit(retrieveConfiguration);
    },
    validationSchema: retrieveConfigurationSchema,
    validateOnMount: true,
  });

  return (
    <form style={{ width: '100%' }} onSubmit={handleSubmit}>
      <Stack direction="column" spacing={2}>
        <Box sx={{ width: '100%' }}>
          <Typography sx={{
            typography: { xs: 'body1', sm: 'h2' },
            mb: 1,
          }}
          >
            Email Address
          </Typography>
          <TextField
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email && errors.email}
            name="email"
            fullWidth
          />
        </Box>
        <Box sx={{ width: '100%' }}>
          <Typography sx={{
            typography: { xs: 'body1', sm: 'h2' },
            mb: 1,
          }}
          >
            Postcode
          </Typography>
          <TextField
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.postcode && values.postcode.toUpperCase()}
            error={touched.postcode && Boolean(errors.postcode)}
            helperText={touched.postcode && errors.postcode}
            name="postcode"
            fullWidth
          />
        </Box>
      </Stack>
      <ActionButtons
        isValid={isValid}
        backPath="/"
        submitText="Get Configuration"
      />
    </form>
  );
}

export default RetrieveConfigurationForm;
