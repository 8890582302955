import Alert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import {
  MountType, RoofDto, RoofPanelGridOrientation, UpdatePanelGridOrientationForm
} from 'providers/api';
import { ChangeEvent, useEffect } from 'react';
import { Nullable } from 'utils/types';
import { mixed, object, SchemaOf } from 'yup';

interface RoofPanelOrientationProps {
  currentRoof: RoofDto
  onUpdatePanelOrientationSubmit: (data: UpdatePanelGridOrientationForm) => void
  orientationMutationIsProcessing: boolean
}

const changePanelOrientationSchema: SchemaOf<UpdatePanelGridOrientationForm> = object({
  panelOrientation: mixed<RoofPanelGridOrientation>().required(),
  mountType: mixed<MountType>().required(),
});

export type IUpdateRoofPanelOrientationForm = Nullable<UpdatePanelGridOrientationForm>

function PanelOrientation({ currentRoof, onUpdatePanelOrientationSubmit, orientationMutationIsProcessing }: RoofPanelOrientationProps) {
  const formik = useFormik<IUpdateRoofPanelOrientationForm>({
    initialValues: {
      panelOrientation: currentRoof.panelOrientation ?? RoofPanelGridOrientation.Portrait,
      mountType: currentRoof.mountType,
    },
    onSubmit: (form, { setSubmitting }) => {
      setSubmitting(true);
      const changePanelOrientationForm = changePanelOrientationSchema.validateSync(form);
      onUpdatePanelOrientationSubmit(changePanelOrientationForm);
    },
    validationSchema: changePanelOrientationSchema,
    enableReinitialize: true,
  });

  const {
    handleSubmit, setFieldValue, submitForm, values,
  } = formik;

  const handleChange = (event: ChangeEvent<HTMLInputElement>, value: string) => {
    setFieldValue('panelOrientation', value);
    submitForm();
  };

  useEffect(() => {
    setFieldValue('panelOrientation', currentRoof.panelOrientation);
  }, [currentRoof.roofId]);

  return (
    <form
      onSubmit={handleSubmit}
      id="change-panel-orientation-form"
    >
      <Typography variant="h2">Panel Orientation</Typography>
      {
        currentRoof.mountType === MountType.InRoof
        && (
        <Alert sx={{ marginY: 1 }} severity="info">
          Landscape is not available for In-roof mounting
        </Alert>
        )
      }
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="panelOrientation"
          name="panelOrientation"
          value={values.panelOrientation}
          onChange={handleChange}
        >
          <Stack spacing={1} direction="row">
            <FormControlLabel
              id="panelOrientation"
              value={RoofPanelGridOrientation.Portrait}
              control={<Radio />}
              label="Portrait"
              disabled={
                currentRoof.mountType === MountType.InRoof
              || orientationMutationIsProcessing
              }
            />
            <FormControlLabel
              id="panelOrientation"
              value={RoofPanelGridOrientation.Landscape}
              control={<Radio />}
              label="Landscape"
              disabled={
                currentRoof.mountType === MountType.InRoof
              || orientationMutationIsProcessing
              }
            />
          </Stack>
        </RadioGroup>
      </FormControl>
    </form>
  );
}

export default PanelOrientation;
