import { RoofDto, RoofPanelGridOrientation } from 'providers/api';
import { metresToCentimetres, millimetresToCentimetres } from 'utils/dimensionConversions';

// STAGE
export const stageHeight = 950;
export const stageWidth = 1400;
export const stagePaddingX = 200;
export const stagePaddingY = 200;

// SOLAR PANEL MARGINS
export const solarPanelMarginX = 1; // e.g. 1cm
export const solarPanelMarginY = 1; // e.g. 1cm
export const totalSolarPanelMarginX = solarPanelMarginX * 2; // e.g. 1cm each side, leaving a 2cm gap between panels
export const totalSolarPanelMarginY = solarPanelMarginY * 2;

// Validation logic
export const roofSizeValidationInMetres = {
  width: {
    min: 2,
    max: 30,
  },
  height: {
    min: 1.8,
    max: 30,
  },
};

export const NUMBER_SUNNY_HOURS_PER_ANNUM = 1493;

const getDimensions = (currentRoof: RoofDto) => {
  // current scale is roughly 1cm = 1px. Below a scale factor will be applied to fit to the stage.

  // ROOF
  const totalRoofWidth = metresToCentimetres(currentRoof.widthInMetres); // cm
  const totalRoofHeight = metresToCentimetres(currentRoof.heightInMetres); // cm
  const roofMargin = 20; // cm
  const availableRoofWidth = totalRoofWidth - roofMargin; // cm - 6 metre - this is the gridWidth
  const availableRoofHeight = totalRoofHeight - roofMargin; // cm - 3 metre - gridHeight
  const roofTotalSize = availableRoofWidth * availableRoofHeight; // 180,000cm2 (18m2)

  // SCALE FACTOR
  // This translate the current scale to the screen size
  const scaleFactorX = (stageWidth - stagePaddingX) / availableRoofWidth;
  const scaleFactorY = (stageHeight - stagePaddingY) / availableRoofHeight;
  //
  const scaleFactor = availableRoofHeight * scaleFactorX > (stageHeight - stagePaddingY)
    ? scaleFactorY
    : scaleFactorX;

  //  TILE BACKGROUND
  const tileHeight = 20; // cm
  const tileWidth = 25; // cm
  const offSetAmount = tileWidth / 2;
  const tileGrid = {
    numberOfTilesAcross: Math.floor(totalRoofWidth / tileWidth), // 24
    numberOfTilesDown: Math.floor(totalRoofHeight / tileHeight), // 15
    // 24 x 15
  };
  const numberOfTiles = tileGrid.numberOfTilesAcross * tileGrid.numberOfTilesDown;
  const roofTileContainerHeight = totalRoofHeight * scaleFactor;
  const roofTileContainerWidth = totalRoofWidth * scaleFactor;
  const roofTileContainerX = (stageWidth - (roofTileContainerWidth)) / 2;
  const roofTileContainerY = (stageHeight - (roofTileContainerHeight)) / 2;

  // SOLAR PANEL ARRAY
  if (!currentRoof?.panelGridRows) throw new Error('No solar panels in the grid');

  // As we need to iterate over on object, we use convert object to an array.
  const solarPanelArray = Object.values(currentRoof.panelGridRows);

  const numberOfPanelsAcross = currentRoof.panelGridRows[0].slots.length;
  const numberOfPanelsDown = Object.keys(currentRoof.panelGridRows).length;

  // SOLAR PANEL DIMENSIONS
  if (!currentRoof.panel?.width || !currentRoof.panel?.height) throw new Error('No dimensions for this solar panel');
  const solarPanelWidth = millimetresToCentimetres(currentRoof.panel.width); // e.g. 113.4cm
  const solarPanelHeight = millimetresToCentimetres(currentRoof.panel.height); // e.g. 172.2cm

  // SOLAR PANEL ORIENTATION
  const panelBaseDimensionX = currentRoof.panelOrientation === RoofPanelGridOrientation.Portrait
    ? solarPanelWidth
    : solarPanelHeight;
  const panelBaseDimensionY = currentRoof.panelOrientation === RoofPanelGridOrientation.Portrait
    ? solarPanelHeight
    : solarPanelWidth;

  if (!panelBaseDimensionX || !panelBaseDimensionY) throw new Error('Dimensions could not be calculated');

  const panelBaseDimensionXWithMargin = panelBaseDimensionX + totalSolarPanelMarginX;
  const panelBaseDimensionYWithMargin = panelBaseDimensionY + totalSolarPanelMarginY;

  return {
    roofTotalSize,
    solarPanelArray,
    numberOfPanelsAcross,
    numberOfPanelsDown,
    solarPanelWidth,
    solarPanelHeight,
    panelBaseDimensionX,
    panelBaseDimensionY,
    panelBaseDimensionXWithMargin,
    panelBaseDimensionYWithMargin,
    scaleFactor,
    totalRoofHeight,
    totalRoofWidth,
    tileWidth,
    tileHeight,
    offSetAmount,
    tileGrid,
    numberOfTiles,
    roofTileContainerHeight,
    roofTileContainerWidth,
    roofTileContainerX,
    roofTileContainerY,
  };
};
export default getDimensions;
