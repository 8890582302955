import { useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import TopBar from 'components/TopBar';
import React from 'react';

type PageType = 'standard' | 'full';
interface PageTypeInfo {
  maxWidth?: number | string;
  height?: number | string;
  p?: number;
  display?: string;
  justifyContent?: string;
  alignSelf?: string;
  alignItems?: string;
  width?: string;
  marginTop?: string
  gridColumn: string
}
interface PageProps {
  children: React.ReactNode;
  pageType?: PageType;
  middleAlignOnMobile?: 'flexStart' | 'center'
}
function Page({ children, pageType = 'standard', middleAlignOnMobile = 'flexStart' }: PageProps) {
  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const pageTypes: Record<PageType, PageTypeInfo> = {
    standard: {
      gridColumn: lgUp ? '5 / span 7' : '2 / span 13',
    },
    full: {
      p: 4,
      gridColumn: 'span 15',
      width: '100%',
    },
  };
  return (
    <Box sx={{
      height: '100%',
      width: '100%',
      display: 'grid',
      gridTemplateColumns: 'repeat(15, minmax(10px, 1fr))',
      gridTemplateRows: '1fr 4fr 1fr',
    }}
    >
      <Box gridColumn="span 15">
        <TopBar />
      </Box>
      <Box sx={{
        ...pageTypes[pageType],
        placeSelf: { xs: middleAlignOnMobile, lg: 'center' },
        width: '100%',
      }}
      >
        {children}
      </Box>
      <Box gridColumn="span 15" />
    </Box>
  );
}
export default Page;
