/* eslint-disable no-unused-expressions */
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useLocalStorage from '@rehooks/local-storage';
import Loading from 'components/Loading';
import { LOCAL_STORAGE_PREFIX } from 'config';
import Page from 'Page';
import { useDeleteRoof, useListRoofsQuery } from 'providers/api/useRoof';
import React, { ReactElement } from 'react';
import ConfirmConfigurationsForm from '../ConfirmConfigurationsForm';

export default function ConfirmConfigurationsPage(): ReactElement {
  const [configurationId] = useLocalStorage<string>(`${LOCAL_STORAGE_PREFIX}_configurationId`);

  const {
    data: listRoofsData,
    isLoading: listRoofsDataIsLoading,
  } = useListRoofsQuery(configurationId ?? '', {
  });
  const deleteRoofMutation = useDeleteRoof();

  const handleDeleteRoofConfiguration = (roofId: string) => {
    if (!configurationId) return;
    const deletePayload = {
      configurationId,
      roofId,
      command: {},
    };
    deleteRoofMutation.mutate(deletePayload);
  };

  if (listRoofsDataIsLoading) return <Loading />;

  return (
    <Page>
      <Typography sx={{
        typography: { xs: 'h6', sm: 'h1' },
        mb: { xs: 2, sm: 4 },
      }}
      >
        Confirm roof configurations
      </Typography>
      <Stack spacing={3}>
        <Alert severity="info">
          {`You have ${listRoofsData?.length} roof ${
            listRoofsData?.length === 1
              ? 'configuration'
              : 'configurations'
          },
          please check the details below and confirm that you are happy with the configuration specified.`}
        </Alert>
        <ConfirmConfigurationsForm
          listRoofsData={listRoofsData ?? []}
          onDeleteRoofConfiguration={handleDeleteRoofConfiguration}
        />
      </Stack>
    </Page>
  );
}
