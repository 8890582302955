/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
import createRoofTiles from 'modules/Configurator/ConfiguratorGrid/createRoofTiles';
import { Application } from 'pixi.js';
import { PanelGridRowDto, RoofDto } from 'providers/api';
import createEmptyPanelWithPlus from './createEmptyPanelWithPlus';
import {
  createLabels,
  drawVector
} from './createLabels';
import createPanelDividerLine from './createPanelDividerLine';
import createSolarPanels from './createSolarPanels';
import createWires from './createWires';
import getDimensions, { stageWidth } from './getDimensions';

interface DrawRoofProps {
  app: Application
  currentRoof: RoofDto
  onUpdatePanelGrid?: (gridRows: PanelGridRowDto[]) => void,
  panelGrid: PanelGridRowDto[]
}

function drawRoof({
  app, currentRoof, onUpdatePanelGrid, panelGrid,
}: DrawRoofProps) {
  const {
    totalRoofHeight,
    totalRoofWidth,
    roofTileContainerX,
    roofTileContainerY,
    roofTileContainerHeight,
    roofTileContainerWidth,
    scaleFactor,
  } = getDimensions(currentRoof);
  //  ROOF TILES
  createRoofTiles(app, currentRoof);

  // SOLAR PANEL
  const { solarPanelContainer, solarPanelBaseContainer } = createSolarPanels(
    {
      currentRoof, onUpdatePanelGrid, panelGrid,
    },
  );
  const { wireContainer, individualWireContainer } = createWires({ solarPanelContainer, currentRoof });
  const {
    emptyPanelWithPlusContainer, emptyPanelWithPlusBaseContainer,
  } = createEmptyPanelWithPlus({
    currentRoof, onUpdatePanelGrid, panelGrid,
  });

  // LABELS
  const {
    downArrow, rightArrow, roofRidgeTopLine, roofHeightLeftLine, createText,
  } = createLabels(app, solarPanelContainer, currentRoof);

  // Text
  createText(app, 'Roof ridge', 'roofHeaderTextStyle', stageWidth / 2, roofTileContainerY - 65);
  createText(
    app,
    'Gutter',
    'roofHeaderTextStyle',
    stageWidth / 2,
    roofTileContainerY + roofTileContainerHeight + (scaleFactor > 1.2 ? 20 : 60),
  );
  createText(
    app,
    `${Math.round(totalRoofWidth * 10)}mm`,
    'totalRoofDimensionsTextStyle',
    app.screen.width / 2,
    roofTileContainerY - 37.5,
  );
  createText(
    app,
    `${Math.round(totalRoofHeight * 10)}mm`,
    'totalRoofDimensionsTextStyle',
    roofTileContainerX - (scaleFactor > 1.2 ? 45 : 70),
    app.screen.height / 2,
    270,
  );

  // Arrows
  drawVector(
    app,
    [roofTileContainerX + roofTileContainerWidth, roofTileContainerY - 20],
    rightArrow,
  );
  drawVector(
    app,
    [roofTileContainerX - (scaleFactor > 1.2 ? 23 : 48), roofTileContainerY + roofTileContainerHeight],
    downArrow,
  );

  // Lines
  drawVector(app, [roofTileContainerX, roofTileContainerY - 20], roofRidgeTopLine);
  drawVector(app, [roofTileContainerX - (scaleFactor > 1.2 ? 25 : 50), roofTileContainerY], roofHeightLeftLine);
  const panelDividerLineContainer = createPanelDividerLine({ currentRoof, solarPanelContainer });

  // Add containers to stage
  app.stage.addChild(emptyPanelWithPlusContainer);
  app.stage.addChild(wireContainer);
  app.stage.addChild(solarPanelContainer);
  app.stage.addChild(panelDividerLineContainer);

  return {
    solarPanelBaseContainer,
    individualWireContainer,
    emptyPanelWithPlusBaseContainer,
  };
}

export default drawRoof;
