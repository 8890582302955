/* eslint-disable import/prefer-default-export */
import { MountType, PanelBrandDto, PanelDto } from './api';
import { ListKey, makeListQuery } from './useApi';

const PANELS_KEY = 'panels' as ListKey;
// const PANEL_KEY = 'panel' as GetKey;

export const useListBrandsQuery = makeListQuery<void, PanelBrandDto[]>(
  PANELS_KEY,
  (api) => () => api.panels.getBrands(),
);

interface ListProductsParams {
  brand?: string,
  mountType?: MountType | null
}

export const useListAllProductsQuery = makeListQuery<ListProductsParams, PanelDto[]>(
  PANELS_KEY,
  (api, params) => () => api.panels.getAll(params.brand, params.mountType),
);
