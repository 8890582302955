import Tune from '@mui/icons-material/Tune';
import { SelectChangeEvent } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import useLocalStorage from '@rehooks/local-storage';
import { LOCAL_STORAGE_PREFIX } from 'config';
import { useSnackbar } from 'notistack';
import Page from 'Page';
import {
  PanelDto, PanelGridRowDto, UpdatePanelGridOrientationForm, UpdateRoofPanelForm
} from 'providers/api';
import useApi from 'providers/api/useApi';
import { useListAllProductsQuery, useListBrandsQuery } from 'providers/api/usePanels';
import { useListRoofsQuery, useUpdatePanel, useUpdatePanelGridOrientation } from 'providers/api/useRoof';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isNilOrEmpty } from 'utils/logic';
import ConfiguratorForm from '../ConfiguratorForm';
import { NUMBER_SUNNY_HOURS_PER_ANNUM } from '../ConfiguratorGrid/getDimensions';
import usePixi from '../ConfiguratorGrid/usePixi';
import ConfiguratorIsLoading from '../ConfiguratorIsLoading/ConfiguratorIsLoading';
import ConfiguratorStage from '../ConfiguratorStage';

export const clearDuplicatesInArray = (ArrayWithPotentialDuplicates: PanelDto[]) => {
  const arrayOfSkus = Array.from(new Set(ArrayWithPotentialDuplicates.map((array) => array.sku)));
  const newArray = arrayOfSkus.map((sku) => ArrayWithPotentialDuplicates.find((product) => product.sku === sku));
  return newArray;
};

function ConfiguratorPage() {
  const [configurationId] = useLocalStorage<string>(`${LOCAL_STORAGE_PREFIX}_configurationId`);
  const [brandSelected, setBrandSelected] = useState('');

  // Needed to seperate the same query into two - one for pixi stage and one for the form. This ensures the form changes don't cause pixi to rerender & flash.
  const {
    data: listRoofsData,
    isLoading: listRoofsDataIsLoading,
    refetch: refetchListRoofData,
    remove: listRoofsDataRemove,
  } = useListRoofsQuery(configurationId ?? '', {
    refetchOnWindowFocus: false,
  });

  const [currentSelectedRoofId, setCurrentSelectedRoofId] = useLocalStorage<string>(
    `${LOCAL_STORAGE_PREFIX}_currentSelectedRoofId`,
  );

  // Similar to the quieries, we need to seperate the current roof for stage and for form to stop rerenders/flashes on the stage.
  const currentRoof = listRoofsData?.find((roof) => roof.roofId === currentSelectedRoofId);

  const { data: brandsData, isLoading: brandsIsLoading } = useListBrandsQuery();
  const { data: productsData, isLoading: productsIsLoading } = useListAllProductsQuery({
    brand: brandSelected,
    mountType: currentRoof?.mountType,
  });
  const navigate = useNavigate();
  const stageIsLoading = React.useRef<Boolean>(false);
  const [uniqueProductsData, setUniqueProductsData] = useState<PanelDto[] | undefined>();
  const updatePanelMutation = useUpdatePanel(configurationId ?? '');
  const updatePanelGridOrientation = useUpdatePanelGridOrientation(configurationId ?? '');
  const api = useApi();
  const pixiElement = React.useRef<HTMLDivElement>(null);
  const [numberOfPanels, setNumberOfPanels] = useState<number>();
  const [triggerFadeIn, setTriggerFadeIn] = useState(true);
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const { enqueueSnackbar } = useSnackbar();
  const [installedCapacity, setInstalledCapacity] = useState<number>();
  const [estimatedAnnualOutput, setEstimatedAnnualOutput] = useState<number>();
  const [panelGrid, setPanelGrid] = useState<PanelGridRowDto[]>();
  const [orientationMutationIsProcessing, setOrientationMutationIsProcessing] = useState(false);

  // used to calculate installed capacity in panel information section
  const getInstalledCapacity = () => {
      if (numberOfPanels === 0) setInstalledCapacity(0);
      numberOfPanels && currentRoof?.panel?.ratedPower && setInstalledCapacity(currentRoof.panel.ratedPower * numberOfPanels);
    };

  // only runs after getInstalledCapacity is called (required in calculations). Also used in panel information section
  const getEstimatedAnnualOutput = () => {
    if (installedCapacity === 0) setEstimatedAnnualOutput(0);
      if (!installedCapacity) return;
      const totalWatt = installedCapacity * NUMBER_SUNNY_HOURS_PER_ANNUM;
        const totalKiloWatt = totalWatt / 1000;
        const estimatedOutputPerAnnum = Number((totalKiloWatt * 0.73).toFixed(2));
        setEstimatedAnnualOutput(estimatedOutputPerAnnum);
    };

    // When we have the installed capacity, we can only then work out the estimated annual output
    useEffect(() => {
      getEstimatedAnnualOutput();
    }, [installedCapacity]);

    // panel grid array layout submitted on clicking the 'Next' button.
    const handleNextSubmit = () => {
      if (!panelGrid) return;
      api.roofs.updatePanelGrid(configurationId ?? '', currentSelectedRoofId ?? '', { panelGridRows: panelGrid })
      .then(() => navigate('/confirm-configurations'));
    };

    // This happens on every click of each panel from Pixi (the HTML 5 creation engine).
    // It saves in state (not network request) until the user changes roof or clicks 'next', then does the mutation.
  const handleUpdateNumberOfPanels = (gridRows: PanelGridRowDto[]) => {
              const solarPanelArray = Object.values(gridRows);
              const newArr = solarPanelArray.map(
                (prevRowObject) => prevRowObject.slots.filter((prevPanel) => (
                  prevPanel.hasPanel === true
                )),
              );
              setNumberOfPanels(newArr.flat().length);
              setPanelGrid(gridRows);
  };

  // This gets the HTML div element to paint the canvas on the page. We also pass props to the stage from here.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const pixi = usePixi({
    currentRoof,
    onUpdatePanelGrid: handleUpdateNumberOfPanels,
    pixiElement: pixiElement.current,
    stageIsLoading,
  });

  // Handles user changing panel grid from portrait to array and visa versa.
  // Network request happens on every click of each radio button.
  const handleUpdatePanelOrientationSubmit = (data: UpdatePanelGridOrientationForm) => {
    setOrientationMutationIsProcessing(true);
    const updateOrientationPayload = {
      roofId: currentSelectedRoofId ?? '',
      command: data,
    };
    updatePanelGridOrientation.mutate(updateOrientationPayload, {
      onSuccess: () => {
        refetchListRoofData();
      },
      onSettled: () => {
        setOrientationMutationIsProcessing(false);
      },
    });
  };

  // Mutation that happens on every click of changing a panel in the 'Change Panel' dropdown.
  const handleChangePanelSubmit = (data: UpdateRoofPanelForm) => {
    const updatePayload = {
      roofId: currentSelectedRoofId ?? '',
      command: data,
    };
    updatePanelMutation.mutate(updatePayload, {
      onSuccess: () => {
        refetchListRoofData();
      },
    });
  };

  // Handles when changing roof in the dropdown.
  // It will do a mutation of the current grid (what is clicked to display or not).
  const handleRoofSelection = (event: SelectChangeEvent<string>) => {
    if (!panelGrid) return;
      api.roofs.updatePanelGrid(configurationId ?? '', currentSelectedRoofId ?? '', { panelGridRows: panelGrid })
      .then(() => {
      setCurrentSelectedRoofId(event.target.value);
      refetchListRoofData();
      })
      .finally(() => {
        setTriggerFadeIn((prev: boolean) => !prev);
        setTimeout(() => {
          setTriggerFadeIn((prev: boolean) => !prev);
        }, 100);
        });
    };

  // Once listRoofData has loaded and if there's no previous roof selected.
  // This shouldn't happen as the currentRoofID is saved in local storage when roof is created/edited,
  // but acts as a fall back just in case.
  useEffect(() => {
    if (listRoofsData && listRoofsData.length > 0 && !currentSelectedRoofId) {
      setCurrentSelectedRoofId(listRoofsData[0].roofId);
      setNumberOfPanels(currentRoof?.amountOfPanels);
      setPanelGrid(currentRoof?.panelGridRows);
      getInstalledCapacity();
      setTriggerFadeIn((prev) => !prev);
      setTimeout(() => {
        setTriggerFadeIn((prev) => !prev);
      }, 100);
    }
  }, [listRoofsData]);

  // Manages the state before the get request re-renders the amount. Keeps amount in sync when changing roofs
  useEffect(() => {
    if (currentRoof?.amountOfPanels === 0) {
      setNumberOfPanels(0);
    }
    if (currentRoof?.amountOfPanels) {
      setNumberOfPanels(currentRoof?.amountOfPanels);
    }
  }, [currentRoof]);

    // updates the capacity and annual output in panel information on changes.
    useEffect(() => {
      if (listRoofsData && listRoofsData.length > 0 && currentSelectedRoofId) {
        setPanelGrid(currentRoof?.panelGridRows);
        getInstalledCapacity();
      }
    }, [numberOfPanels, currentRoof?.panel?.ratedPower, currentRoof?.panelGridRows]);

  // check for out of stock and activates fade on listRoofData changing
  useEffect(() => {
    if (listRoofsData && listRoofsData.length > 0 && currentSelectedRoofId) {
      const isPrevPanelOutOfStock = listRoofsData?.some((roof) => (
        // eslint-disable-next-line max-len
        roof.panelStockMessage === 'One of your selected panels has gone out of stock. We have replaced it with an in stock panel. Please double check your roofs before proceeding'
      ));
      if (isPrevPanelOutOfStock) {
        enqueueSnackbar(
          `One of your selected panels has gone out of stock. 
          We have replaced it with an in stock panel, so please double check your roofs before proceeding`,
          { variant: 'error', autoHideDuration: 7000 },
        );
      }

      // Activates fade effect when required from listRoofData changing.
      setTriggerFadeIn((prev) => !prev);
      setTimeout(() => {
        setTriggerFadeIn((prev) => !prev);
      }, 100);
    }
  }, [listRoofsData]);

  // Remove duplicate product data if it comes from google sheets.
  useEffect(() => {
    if (productsData?.length === 0) setUniqueProductsData(undefined);
    if (productsData && productsData?.length > 0) {
      const productsArrayNoDuplicates = clearDuplicatesInArray(productsData);
      if (isNilOrEmpty(productsArrayNoDuplicates)) {
        // eslint-disable-next-line no-console
        console.warn('List of products had no value and returned silently');
        return;
      }
      setUniqueProductsData(productsArrayNoDuplicates as PanelDto[]);
    }
  }, [productsData]);

  // Rerenders the visualiser in response to screen size growing, as visualiser turned off below md brekapoint
  useEffect(() => {
    refetchListRoofData();
  }, [mdUp]);

  const stageDataIsLoading = brandsIsLoading
  || productsIsLoading
  || listRoofsDataIsLoading
  || listRoofsDataIsLoading
  || !currentRoof
  || !currentRoof
  || (stageIsLoading.current && mdUp);

  if (listRoofsData && !listRoofsDataIsLoading && currentRoof?.panelGridRows && currentRoof?.panelGridRows.length === 0) {
 return (
   <Page>
     <Stack direction="column" spacing={2} alignItems="center">
       <Alert
         severity="info"
         sx={{ margin: 'auto' }}
       >
         Sorry, no panels are available for your roof dimensions. Please click the button below to edit your roof settings.
       </Alert>
       <Button
         variant="contained"
         color="secondary"
         startIcon={<Tune />}
         sx={{ width: 200 }}
         onClick={() => {
          listRoofsDataRemove();
           navigate(`/edit-roof/${currentRoof?.roofId}`);
         }}
       >
         Edit roof settings
       </Button>
     </Stack>
   </Page>
 );
  }

        return (
          <>
            { stageDataIsLoading && <ConfiguratorIsLoading /> }
            <Page pageType="full">
              <Stack
                direction={{ xs: 'column', lg: 'row' }}
                sx={{ justifyContent: 'center', alignItems: 'center' }}
              >
                <ConfiguratorStage
                  mdUp={mdUp}
                  triggerFadeIn={triggerFadeIn}
                  pixiElement={pixiElement}
                />
                <Box sx={{ margin: { lg: 2 }, width: { md: '100%', lg: '33%' } }}>
                  <ConfiguratorForm
                    listRoofsData={listRoofsData ?? []}
                    brandsData={brandsData ?? []}
                    uniqueProductsData={uniqueProductsData ?? []}
                    brandSelected={brandSelected}
                    setBrandSelected={setBrandSelected}
                    currentRoof={currentRoof}
                    onChangePanelFormSubmit={handleChangePanelSubmit}
                    onUpdatePanelOrientationSubmit={handleUpdatePanelOrientationSubmit}
                    numberOfPanels={numberOfPanels}
                    installedCapacity={installedCapacity}
                    estimatedAnnualOutput={estimatedAnnualOutput}
                    onNextSubmit={handleNextSubmit}
                    onRoofSelection={handleRoofSelection}
                    orientationMutationIsProcessing={orientationMutationIsProcessing}
                  />
                </Box>
              </Stack>
            </Page>
          </>
        );
}
export default ConfiguratorPage;
