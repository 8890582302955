import Chip from '@mui/material/Chip';
import { PanelDto, PanelStockStatus } from 'providers/api';
import { dateStringConversion } from './datetime';

const getPanelChip = (panelProduct?: PanelDto) => {
  if (!panelProduct) return <Chip label="Sold out" color="error" />;
  switch (panelProduct.panelStockStatus) {
    case PanelStockStatus.InStock:
      return <Chip label="In stock" color="primary" />;
    case PanelStockStatus.OutOfStock:
      return <Chip label="Sold out" color="error" />;
    case PanelStockStatus.DueIn:
      return panelProduct.dueInStockDate
        ? <Chip label={`Due: ${dateStringConversion(panelProduct.dueInStockDate)}`} color="warning" />
        : <Chip label="Sold out" color="error" />;
    default:
      return <Chip label="Sold out" color="error" />;
  }
};

export default getPanelChip;
