import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ActionButtons from 'components/ActionButtons';
import SelectionTileList from 'components/SelectionTileList';
import { useFormik } from 'formik';
import { roofSizeValidationInMetres } from 'modules/Configurator/ConfiguratorGrid/getDimensions';
import {
  MountType, RoofDto, RoofType, UpdateRoofForm
} from 'providers/api';
import React from 'react';
import { IJOPOWER_CONTACT_NUMBER } from 'utils/const';
import { ConfigSelectionTile, Nullable } from 'utils/types';
import {
  mixed, number,
  object,
  SchemaOf,
  string
} from 'yup';

const editRoofCommandSchema: SchemaOf<UpdateRoofForm> = object().shape({
  name: string().required('Please enter a roof name'),
  heightInMetres: number()
    .required('Please enter your roof height')
    .typeError('Height must be a number')
    .min(
      roofSizeValidationInMetres.height.min,
      `Please enter a height of ${roofSizeValidationInMetres.height.min} metres or more`,
    )
    .max(
      roofSizeValidationInMetres.height.max,
      `Please enter a height of ${roofSizeValidationInMetres.height.max} metres or less. If you have a bigger roof,
      please contact us on ${IJOPOWER_CONTACT_NUMBER}`,
    ),
  widthInMetres: number()
    .required('Please enter your roof width')
    .typeError('Width must be a number')
    .min(
      roofSizeValidationInMetres.width.min,
      `Please enter a width of ${roofSizeValidationInMetres.width.min} metres or more`,
    )
    .max(
      roofSizeValidationInMetres.width.max,
      `Please enter a width of ${roofSizeValidationInMetres.width.max} metres or less. If you have a bigger roof,
      please contact us on ${IJOPOWER_CONTACT_NUMBER}`,
    ),
  mountType: mixed<MountType>()
    .required()
    .test((mountTypeValue) => (
      mountTypeValue === MountType.InRoof
      || mountTypeValue === MountType.OnRoof
    )),
    roofType: mixed<RoofType>()
    .required()
    .test((roofTypeValue) => roofTypeValue === RoofType.Slate
      || roofTypeValue === RoofType.Tile
      || roofTypeValue === RoofType.Corrugated)
      .when(['mountType'], {
        is: (mountType: any) => mountType === MountType.InRoof,
        then: (schema) => schema.test(
          'if mountType is corrugated, roofType cannot be InRoof - is this valid?',
          'if your mount type is corrugated, your roof type cannot be in roof',
          (roofType) => roofType !== RoofType.Corrugated,
        ),
     }),
});

type IEditRoofForm = Nullable<UpdateRoofForm>

type EditRoofFormProps = {
  roofData?: RoofDto
  onFormSubmit: (form: UpdateRoofForm) => void
  mountTypeTileArray: ConfigSelectionTile[]
  roofTypeTileArray: ConfigSelectionTile[]
  setInRoofandCorrugatedSelected: (selection: boolean) => void
}

function EditRoofForm({
  roofData, onFormSubmit, mountTypeTileArray, roofTypeTileArray, setInRoofandCorrugatedSelected,
}: EditRoofFormProps) {
  const [inRoofSelectedAndPanelPowerAbove370W, setInRoofSelectedAndPanelPowerAbove370W] = React.useState(false);
  const {
    handleSubmit, setFieldValue, values, isValid, handleChange, handleBlur, errors, touched,
  } = useFormik<IEditRoofForm>({
    initialValues: {
      name: roofData?.name ?? '',
      heightInMetres: roofData?.heightInMetres ?? null,
      widthInMetres: roofData?.widthInMetres ?? null,
      mountType: roofData?.mountType ?? null,
      roofType: roofData?.roofType ?? null,
    },
    onSubmit: (form, { setSubmitting }) => {
      setSubmitting(true);
      const manageRoofForm = editRoofCommandSchema.validateSync(form);
      onFormSubmit(manageRoofForm);
    },
    validationSchema: editRoofCommandSchema,
    enableReinitialize: true,
  });

  React.useEffect(() => {
    if (values.mountType === MountType.InRoof && values.roofType === RoofType.Corrugated) {
      setInRoofandCorrugatedSelected(true);
    } else setInRoofandCorrugatedSelected(false);
  }, [values.mountType, values.roofType]);

    // Resets preferred panel details if in roof is selected as only panels over 370w can be selected
    React.useEffect(() => {
      if (values.mountType === MountType.InRoof && (roofData?.panel?.ratedPower && roofData?.panel?.ratedPower > 370)) {
        setInRoofSelectedAndPanelPowerAbove370W(true);
      } else setInRoofSelectedAndPanelPowerAbove370W(false);
    }, [values.mountType]);

  return (
    <form style={{ width: '100%' }} onSubmit={handleSubmit}>
      <Typography
        sx={{
          typography: { xs: 'h6', sm: 'h1' },
          mb: { xs: 2, sm: 4 },
        }}
      >
        Edit a roof
      </Typography>
      <Stack direction="column" spacing={2}>
        <Stack spacing={2} direction="column" justifyContent="space-between">
          <Box sx={{ width: '100%' }}>
            <Typography
              sx={{
                typography: { xs: 'body1', sm: 'h2' },
              }}
            >
              Roof name
            </Typography>
            <TextField
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name ?? ''}
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name as boolean && errors.name as string}
              sx={{
                width: { xs: '100%', xl: 'calc(50% - 10px)' },
              }}
              placeholder="i.e  South Roof"
            />
          </Box>
          <Stack spacing={2.5} direction="row" justifyContent="space-between">
            <Box sx={{ width: '100%' }}>
              <Typography
                sx={{
                  typography: { xs: 'body1', sm: 'h2' },
                }}
              >
                Roof height
              </Typography>
              <TextField
                name="heightInMetres"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.heightInMetres ?? ''}
                error={touched.heightInMetres && Boolean(errors.heightInMetres)}
                helperText={
                  touched.heightInMetres as boolean && errors.heightInMetres as string
                    ? errors.heightInMetres as string
                    : 'Distance from gutter to ridge tile'
                }
                InputProps={{
                  endAdornment: <InputAdornment position="end">Metres</InputAdornment>,
                }}
                fullWidth
                type="number"
              />
            </Box>
            <Box sx={{ width: '100%' }}>
              <Typography
                sx={{
                  typography: { xs: 'body1', sm: 'h2' },
                }}
              >
                Roof length
              </Typography>
              <TextField
                name="widthInMetres"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.widthInMetres ?? ''}
                error={touched.widthInMetres && Boolean(errors.widthInMetres)}
                helperText={
                  touched.widthInMetres as boolean && errors.widthInMetres as string
                    ? errors.widthInMetres as string
                    : 'Length of the gutter'
                }
                InputProps={{
                  endAdornment: <InputAdornment position="end">Metres</InputAdornment>,
                }}
                fullWidth
                type="number"
              />
            </Box>
          </Stack>
        </Stack>
        <Alert severity="info">
          Unsure about how to measure your roof?
          If you do not have your roof measurements, you can get approximate measurements via Google Earth.
          {' '}
          <Link
            underline="none"
            href="https://support.google.com/earth/answer/9010337?hl=en&amp;co=GENIE.Platform=Desktop"
            target="_blank"
          >
            Click here
          </Link>
          {' '}
          to see how.
        </Alert>
        <Stack spacing={2.5}>
          <SelectionTileList
            questionTitle="How would you like to mount your panels?"
            data={mountTypeTileArray}
            setFieldValue={setFieldValue}
            values={values}
          />
          {
            inRoofSelectedAndPanelPowerAbove370W && (
              <Alert severity="info">
                You have selected an In Roof mount as well as an over 370W panel product.
                As In Roof mounts can only fit panels with 370W or below power, we have changed your previously chosen panel.
                Please proceed, check the new panel product and change should you prefer a different panel product.
              </Alert>
            )
          }
          <SelectionTileList
            questionTitle="Roof type"
            data={roofTypeTileArray}
            setFieldValue={setFieldValue}
            values={values}
          />
        </Stack>
      </Stack>
      <ActionButtons
        isValid={isValid}
        backPath={-1}
      />
    </form>
  );
}

export default EditRoofForm;
