/* eslint-disable no-unused-expressions */
import { Check } from '@mui/icons-material';
import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import useTheme from '@mui/material/styles/useTheme';
import Typography from '@mui/material/Typography';
import { FormikHelpers, FormikState } from 'formik';
import React, { ReactElement } from 'react';

export type SelectionTileType = {
  label: string
  field?: string
  value?: string | number
  buttonIcon?: ReactElement
  buttonAction?: () => void
  setFieldValue?: FormikHelpers<any>['setFieldValue']
  values?: FormikState<any>['values']
  recommendedChip?: boolean
  hidden?: boolean
}

function SelectionTile({
  label, field, value, buttonIcon, buttonAction, setFieldValue, values, recommendedChip = false, hidden = false,
}: SelectionTileType) {
  const theme = useTheme();
  const isSelected = (field && values) && value === values[field];
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Box
      sx={{
        backgroundColor: isSelected
          ? theme.palette.primary.main
          : theme.palette.background.default,
        border: `1px solid ${theme.palette.primary.main}`,
        transition: 'transform .2s',
        '&:hover': {
          transform: 'scale(1.05)',
        },
        ...(isSelected && {
          '&:hover': {
            backgroundColor: theme.palette.primary.main,
            transform: 'scale(1.05)',
          },
          '& .MuiSvgIcon-root': {
            color: theme.palette.background.default,
          },
          '& .MuiTypography-root': {
            color: theme.palette.background.default,
            fontColor: theme.palette.background.default,
          },
        }),
        borderRadius: 0,
        width: '100%',
        paddingTop: '90%', // Aspect Ratio
        position: 'relative',
        hidden: hidden ? 'hidden' : 'visible',
      }}
    >
      <Button
        onClick={() => {
          buttonAction && buttonAction();
          field && setFieldValue && setFieldValue(field, value);
        }}
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
        }}
      >
        <Check sx={{
          color: theme.palette.background.default,
          position: 'absolute',
          top: '6px',
          left: '10px',
          fontSize: lgUp ? '0.9vw' : '2.7vw',
        }}
        />
        <Stack sx={{ display: 'flex', alignItems: 'center' }}>
          {buttonIcon}
          <Typography
            color="primary"
            fontWeight={500}
            sx={{ fontSize: lgUp ? '0.9vw' : '2.7vw' }}
          >
            {label}
          </Typography>
        </Stack>
        {
          recommendedChip
          && <Chip label="Recommended" color="secondary" sx={{ position: 'absolute', top: '164px' }} />
        }
      </Button>
    </Box>
  );
}
export default SelectionTile;
