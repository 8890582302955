import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import Page from '../../../Page';

function PageNotFoundPage() {
  const navigate = useNavigate();
  return (
    <Page>
      <Stack spacing={2} alignItems="center">
        <Typography variant="h1">
          Sorry, this page could not be found
        </Typography>
        <Button size="large" onClick={() => navigate(-1)}>Go back to previous page</Button>
      </Stack>
    </Page>
  );
}
export default PageNotFoundPage;
