import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ProtectedRoute from 'components/ProtectedRoute';
import 'index.css';
import AddRoofPage from 'modules/AddRoof/AddRoofPage';
import ConfiguratorPage from 'modules/Configurator/ConfiguratorPage';
import ConfirmConfigurationPage from 'modules/ConfirmConfigurations/ConfirmConfigurationsPage';
import CustomerTypePage from 'modules/CustomerType/CustomerTypePage';
import EditRoofPage from 'modules/EditRoof/EditRoofPage';
import InstallationDetailsPage from 'modules/InstallationDetails/InstallationDetailsPage';
import PowerUsagePage from 'modules/PowerUsage/PowerUsagePage';
import Redirect from 'modules/Redirect';
import RetrieveConfigurationPage from 'modules/RetrieveConfiguration/RetrieveConfigurationPage';
import PageNotFoundPage from 'modules/utils/PageNotFoundPage';
import Page from 'Page';
import { ReactElement } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import theme from 'styles/theme';
import { basePath, IJOPOWER_EMAIL_ADDRESS } from 'utils/const';
import ScrollToTop from 'utils/ScrollToTop';
import './App.css';

function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
   return (
     <Page>
       <Box
         sx={{
          backgroundColor: theme.palette.background.default,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
       >
         <Alert
           sx={{
            minWidth: 400,
            maxHeight: 700,
          }}
           severity="error"
         >
           <Typography variant="subtitle2">
             {`Sorry, something went wrong. Please contact us at ${IJOPOWER_EMAIL_ADDRESS} quoting the below:` }
           </Typography>
           <pre>{error.message}</pre>
           <Button onClick={resetErrorBoundary}>Retry</Button>
         </Alert>
       </Box>
     </Page>
   );
}

function App(): ReactElement {
  return (
    <BrowserRouter basename={basePath}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <ScrollToTop />
        <Routes>

          {/* New Configuration Sections */}
          <Route path="/" element={<CustomerTypePage />} />

          {/* Existing Configuration 'Log in' */}
          <Route path="/existing" element={<RetrieveConfigurationPage />} />

          {/*
            Shared between new and existing, configuration Id is needed to access.
            For new users, a configuration is provided on submit on the power usage form.
            For exisitng users, they get a configuration Id back from form submission on existing.
        */}
          <Route
            path="/new/power-usage"
            element={(
              <ProtectedRoute>
                <PowerUsagePage />
              </ProtectedRoute>
        )}
          />
          <Route
            path="/add-roof"
            element={(
              <ProtectedRoute>
                <AddRoofPage />
              </ProtectedRoute>
        )}
          />
          <Route
            path="/edit-roof/:roofId"
            element={(
              <ProtectedRoute>
                <EditRoofPage />
              </ProtectedRoute>
                  )}
          />
          <Route
            path="/configurator"
            element={(
              <ProtectedRoute>
                <ConfiguratorPage />
              </ProtectedRoute>
                  )}
          />
          <Route
            path="/confirm-configurations"
            element={(
              <ProtectedRoute>
                <ConfirmConfigurationPage />
              </ProtectedRoute>
                  )}
          />
          <Route
            path="/installation"
            element={(
              <ProtectedRoute>
                <InstallationDetailsPage />
              </ProtectedRoute>
                  )}
          />
          <Route path="/redirect" element={<Redirect />} />
          {/* 404 */}
          <Route path="*" element={<PageNotFoundPage />} />

        </Routes>
      </ErrorBoundary>
    </BrowserRouter>
  );
}
export default App;
