import { CssBaseline } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import App from 'App';
import TopBar from 'components/TopBar';
import 'index.css';
import { SnackbarProvider } from 'notistack';
import { ApiProvider, withQueryClientDefaults } from 'providers/api';
import React from 'react';
import ReactDOM from 'react-dom';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import theme from 'styles/theme';
import { IJOPOWER_EMAIL_ADDRESS } from 'utils/const';

const AppWithQueryClientDefaults = withQueryClientDefaults(App);

function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  return (
    <>
      <TopBar />
      <Box
        sx={{
          backgroundColor: theme.palette.background.default,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Alert
          sx={{
            width: '50%',
            minWidth: 400,
            maxHeight: 700,
          }}
          severity="error"
        >
          <Typography variant="subtitle2">{`Sorry, something went wrong. Please contact us at ${IJOPOWER_EMAIL_ADDRESS} quoting the below:` }</Typography>
          <pre>{error.message}</pre>
          <Button onClick={resetErrorBoundary}>Retry</Button>
        </Alert>
      </Box>
    </>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <ApiProvider>
          <SnackbarProvider
            maxSnack={1}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            preventDuplicate
          >
            <AppWithQueryClientDefaults />
          </SnackbarProvider>
        </ApiProvider>
        <CssBaseline />
      </ErrorBoundary>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
