/* eslint-disable no-unused-vars */
/* eslint-disable no-return-assign */
import SolidGreenLine from 'assets/SolidGreenLine.svg';
import { Container, Sprite, Texture } from 'pixi.js';
import { RoofDto } from 'providers/api';
import getDimensions, {
  stageHeight, stageWidth, totalSolarPanelMarginX, totalSolarPanelMarginY
} from './getDimensions';

interface createPanelDividerLineProps {
  currentRoof: RoofDto
  solarPanelContainer: Container
}

function createPanelDividerLine({ currentRoof, solarPanelContainer }: createPanelDividerLineProps) {
  const {
    solarPanelArray,
    numberOfPanelsAcross,
    panelBaseDimensionX,
    panelBaseDimensionY,
    panelBaseDimensionXWithMargin,
    panelBaseDimensionYWithMargin,
    scaleFactor,
  } = getDimensions(currentRoof);

  const panelDividerLineContainer = new Container();
  const panelDividerLineBaseContainer = new Container();

  panelDividerLineContainer.sortableChildren = true;

  const panelDividerLineTexture = Texture.from(SolidGreenLine);

  solarPanelArray.forEach(
    (rowObject, rowIndex) => {
      const row = rowIndex; // row is the number of objects in the PanelGridArray
      rowObject.slots.forEach((panel, columnIndex) => {
        const column = columnIndex % (numberOfPanelsAcross);
        // e.g. 0000 1111 (4 solar panels on top row, 4 on bottom row).

        const setXPanelWithMargin = (column * panelBaseDimensionX) + (column * totalSolarPanelMarginX);
        const setYPanelWithMargin = (row * panelBaseDimensionY) + (row * totalSolarPanelMarginY);

        const panelDividerLine = new Sprite(panelDividerLineTexture);

        panelDividerLine.zIndex = 4;
        panelDividerLine.height = panelBaseDimensionYWithMargin;
        panelDividerLine.width = panelBaseDimensionXWithMargin;
        panelDividerLine.x = setXPanelWithMargin;
        panelDividerLine.y = setYPanelWithMargin;

        panelDividerLineBaseContainer.addChild(panelDividerLine);
      });
    },
  );
  panelDividerLineContainer.addChild(panelDividerLineBaseContainer);
  panelDividerLineContainer.height = panelDividerLineBaseContainer.height * scaleFactor;
  panelDividerLineContainer.width = panelDividerLineBaseContainer.width * scaleFactor;
  panelDividerLineContainer.x = (stageWidth - solarPanelContainer.width) / 2;
  panelDividerLineContainer.y = (stageHeight - solarPanelContainer.height) / 2;

  return panelDividerLineContainer;
}

export default createPanelDividerLine;
