/* eslint-disable react/jsx-props-no-spreading */
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ActionButtons from 'components/ActionButtons';
import { useFormik } from 'formik';
import { PowerUsageDto, TimePeriod } from 'providers/api';
import { Nullable } from 'utils/types';
import {
  mixed,
  number,
  object
} from 'yup';

// Error with yup type with 'appliances' means we can't enter the PowerUsageDto type like normal.
const powerUsageSchema = object({
  timePeriod: mixed<TimePeriod>()
    .required()
    .test((value) => Object.values(TimePeriod).includes(value as TimePeriod)),
  monthlyElectricityBill: number()
    .min(20, 'Please enter an amount greater than £20')
    .required('Please enter an amount greater than £20')
    .typeError('Amount must be a number'),
});

export type IPowerUsageForm = Nullable<PowerUsageDto>

type PowerUsageFormProps = {
  data?: PowerUsageDto
  onFormSubmit: (data: PowerUsageDto) => void
}

function PowerUsageForm({ data, onFormSubmit }: PowerUsageFormProps) {
  const formik = useFormik<IPowerUsageForm>({
    initialValues: {
      kiloWatts: data?.kiloWatts ?? null,
      timePeriod: data?.timePeriod ?? TimePeriod.Annual,
      monthlyElectricityBill: data?.monthlyElectricityBill ?? null,
    },
    onSubmit: (form, { setSubmitting }) => {
      setSubmitting(true);
      const powerUsageDto = powerUsageSchema.validateSync(form);
      onFormSubmit(powerUsageDto);
    },
    validationSchema: powerUsageSchema,
    validateOnMount: !data,
  });

  const {
    handleSubmit, values, isValid, handleChange, handleBlur, errors, touched,
  } = formik;

  return (
    <form style={{ width: '100%' }} onSubmit={handleSubmit}>
      <Typography
        sx={{
          typography: { xs: 'h6', sm: 'h1' },
          mb: { xs: 2, sm: 4 },
        }}
      >
        Power Usage
      </Typography>
      <Stack direction="column" spacing={2}>
        <Alert severity="info">
          To find out your energy consumption, you can refer to your smart meter readings.
          Alternatively, we recommend looking at your annual statement,
          as provided by your energy provider,
          as this will account for any seasonal variations.
        </Alert>
        <Stack spacing={2} direction="row" justifyContent="space-between">
          <Box sx={{ width: '100%' }}>
            <Typography
              sx={{
                typography: { xs: 'body1', sm: 'h2' },
                mb: 1,
              }}
            >
              How many kWh of electricity do you use?
            </Typography>
            <TextField
              name="kiloWatts"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.kiloWatts ?? ''}
              error={touched.kiloWatts && Boolean(errors.kiloWatts)}
              helperText={touched.kiloWatts as boolean && errors.kiloWatts as string}
              InputProps={{
                endAdornment: <InputAdornment position="end">kWh</InputAdornment>,
              }}
              fullWidth
              type="number"
            />
          </Box>
          <Box sx={{ width: '100%' }}>
            <Typography
              sx={{
                typography: { xs: 'body1', sm: 'h2' },
                mb: 1,
              }}
            >
              Over what time period does that represent?
            </Typography>
            <FormControl
              fullWidth
            >
              <Select
                value={values.timePeriod}
                name="timePeriod"
                onChange={handleChange}
              >
                <MenuItem value={TimePeriod.Annual}>Annual</MenuItem>
                <MenuItem value={TimePeriod.Monthly}>Monthly</MenuItem>
                <MenuItem value={TimePeriod.Quarterly}>Quarterly</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Stack>
        <Box>
          <Typography
            sx={{
              typography: { xs: 'body1', sm: 'h2' },
              mb: 1,
            }}
          >
            Approximately how much are you paying a month for your electricity currently?
          </Typography>
          <TextField
            name="monthlyElectricityBill"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.monthlyElectricityBill ?? ''}
            error={touched.monthlyElectricityBill && Boolean(errors.monthlyElectricityBill)}
            helperText={touched.monthlyElectricityBill as boolean && errors.monthlyElectricityBill as string}
            InputProps={{
              startAdornment: <InputAdornment position="start">£</InputAdornment>,
            }}
            sx={{
              width: { xs: '100%', xl: 'calc(50% - 10px)' },
            }}
            type="number"
          />
        </Box>
      </Stack>
      <ActionButtons
        isValid={isValid}
        hideBackButton
      />
    </form>
  );
}

export default PowerUsageForm;
