/* eslint-disable react/no-unused-prop-types */
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ReactNode } from 'react';

interface OptionDialogInterface {
  title? : string
  description?: string | ReactNode
  optionAButtonText?: string
  onOptionA?: () => void
  optionBButtonText: string
  onOptionB: () => void
  handleClose?: () => void
  showCancelButton?: boolean
}

function OptionDialog({
  title,
  description,
  optionAButtonText,
  onOptionA,
  optionBButtonText,
  onOptionB,
  handleClose,
  showCancelButton = true,
}: OptionDialogInterface) {
  return (
    <Dialog open>
      {title && <DialogTitle id="alert-dialog-title" variant="h6">{title}</DialogTitle> }
      {description && (
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      )}
      <DialogActions sx={{ pb: 3, pr: 3 }}>
        <>
          {showCancelButton
          && <Button variant="text" onClick={handleClose}>Cancel</Button>}
          {onOptionA
          && (
          <Button color="secondary" variant="contained" onClick={onOptionA}>
            {optionAButtonText}
          </Button>
          )}
          <Button color="primary" variant="contained" onClick={onOptionB} autoFocus>
            {optionBButtonText}
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
}

export default OptionDialog;
