import {
  ConfigurationForm,
  UpdateInstallationCommand, UpdateInstallationDetailsCommand
} from '.';
import { ConfigurationDto, CreateConfigurationCommand, PowerUsageDto } from './api';
import {
  GetKey,
  ListKey,
  makeCreateMutation, makeGetQuery,
  makeListQuery,
  makeUpdateMutation
} from './useApi';

const CONFIGURATIONS_KEY = 'configurations' as ListKey;
const CONFIGURATION_KEY = 'configuration' as GetKey;

export const useConfigurationsQuery = makeListQuery<void, ConfigurationDto[]>(
  CONFIGURATIONS_KEY,
  (api) => () => api.configurations.list(),
);

export const useCreateConfiguration = makeCreateMutation<ConfigurationDto, CreateConfigurationCommand>(
  CONFIGURATIONS_KEY,
  (api) => (mutation) => api.configurations.create(mutation),
);

export const useUpdateInstallationMutation = makeUpdateMutation<void, UpdateInstallationCommand>(
  CONFIGURATION_KEY,
  CONFIGURATIONS_KEY,
  (api, configurationId) => (mutation) => api.configurations.updateInstallation(configurationId, mutation),
);

export const useConfigurationQuery = makeGetQuery<ConfigurationDto, string>(
  CONFIGURATION_KEY,
  (api, configurationId) => () => api.configurations.get(configurationId),
);

export const useGetExistingConfiguration = makeCreateMutation<ConfigurationDto, ConfigurationForm>(
  '' as ListKey,
  (api) => (mutation) => api.configurations.getExisting(mutation),
);

export const useUpdatePowerUsageMutation = makeUpdateMutation<void, PowerUsageDto>(
  CONFIGURATION_KEY,
  CONFIGURATIONS_KEY,
  (api, clientId) => (mutation) => api.configurations.updatePowerUsage(clientId, mutation),
);

export const useUpdateInstallationDetails = makeUpdateMutation<void, UpdateInstallationDetailsCommand>(
  null,
  null,
  (api, configurationId) => (mutation) => api.configurations.updateInstallationDetails(configurationId, mutation),
);
