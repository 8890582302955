import { PanelGridRowDto, RoofDto } from 'providers/api';
import { StageChildrenProps } from './usePixi';

interface InteractivityProps {
  currentRoof: RoofDto
  panelGrid: PanelGridRowDto[]
  stageChildren: StageChildrenProps
}

const makeInteractive = ({
  panelGrid, stageChildren,
}: InteractivityProps) => {
  const {
    solarPanelBaseContainer,
    individualWireContainer,
    emptyPanelWithPlusBaseContainer,
  } = stageChildren;

  if (
    !solarPanelBaseContainer
    || !individualWireContainer
    || !emptyPanelWithPlusBaseContainer
  ) throw new Error('Missing interactivity state');

  const prevSolarPanelArray = Object.values(panelGrid);
  prevSolarPanelArray.forEach(
    (rowObject, rowIndex) => {
      rowObject.slots.forEach((panel, columnIndex) => {
        const uniquePanelId = `(${rowIndex}-${columnIndex})`;
        // Visibility in response to click events here.
        const panelSprite = solarPanelBaseContainer?.getChildByName(`solarPanel${uniquePanelId}`);
        panelSprite.visible = panel.hasPanel ?? false;
        const baseGraphic = solarPanelBaseContainer?.getChildByName(`base${uniquePanelId}`);
        baseGraphic.visible = panel.hasPanel ?? false;
        const dropShadowGraphic = solarPanelBaseContainer.getChildByName(`dropShadow${uniquePanelId}`);
        dropShadowGraphic.visible = panel.hasPanel ?? false;
        const wireGraphic = individualWireContainer.getChildByName(`wire${uniquePanelId}`);
        wireGraphic.visible = panel.hasPanel ?? false;
        const emptyPanelWithPlusGraphic = emptyPanelWithPlusBaseContainer.getChildByName(
          `emptyPanelWithPlus${uniquePanelId}`,
        );
        emptyPanelWithPlusGraphic.visible = !panel.hasPanel ?? true;
      });
    },
  );
};

export default makeInteractive;
