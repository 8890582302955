/* eslint-disable max-len */
import RepeatIcon from '@mui/icons-material/Repeat';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import useTheme from '@mui/material/styles/useTheme';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import {
  MountType,
  PanelBrandDto, PanelDto, RoofDto, RoofPanelGridOrientation, UpdateRoofPanelForm
} from 'providers/api';
import React, { useRef, useState } from 'react';
import getPanelChip from 'utils/getPanelChip';
import { notNilOrEmpty } from 'utils/logic';
import { Nullable } from 'utils/types';
import {
  mixed, object,
  SchemaOf,
  string
} from 'yup';
import { ChangePanelList } from '.';

interface PanelInformationProps {
  currentRoof: RoofDto
  onChangePanelFormSubmit: (form: UpdateRoofPanelForm) => void
  brandsData: PanelBrandDto[]
  uniqueProductsData: PanelDto[]
  brandSelected: string
  setBrandSelected: (brand: string) => void
  numberOfPanels?: number
  installedCapacity?: number
  estimatedAnnualOutput?: number
}

const changePanelSchema: SchemaOf<UpdateRoofPanelForm> = object({
  panelSku: string().required(),
  panelOrientation: mixed<RoofPanelGridOrientation>().required(),
  mountType: mixed<MountType>().required(),

});

export type IUpdateRoofPanelForm = Nullable<UpdateRoofPanelForm>

function PanelInfoChangePanel({
  currentRoof, onChangePanelFormSubmit, brandsData, uniqueProductsData,
  brandSelected, setBrandSelected, numberOfPanels, installedCapacity, estimatedAnnualOutput,
}: PanelInformationProps) {
  const selectedPanel = currentRoof.panel;
  const theme = useTheme();
  const [changePanelModalIsOpen, setChangePanelModalIsOpen] = useState(false);

  const formik = useFormik<IUpdateRoofPanelForm>({
    initialValues: {
      panelSku: currentRoof?.panel?.sku ?? '',
      panelOrientation: currentRoof.panelOrientation ?? null,
      mountType: currentRoof.mountType,
    },
    onSubmit: (form, { setSubmitting }) => {
      setSubmitting(true);
      const changePanelForm = changePanelSchema.validateSync(form);
      onChangePanelFormSubmit(changePanelForm);
    },
    validationSchema: changePanelSchema,
  });

  const { handleSubmit, submitForm, values } = formik;

  const isMounted = useRef(false);
  React.useEffect(() => {
    if (isMounted.current) {
      if (currentRoof?.panel?.sku && brandsData && uniqueProductsData) {
        submitForm();
      }
    } else isMounted.current = true;
  }, [values.panelSku]);

  return (
    <form
      onSubmit={handleSubmit}
      id="change-panel-form"
    >
      <Stack spacing={1}>
        <Typography component="span" variant="h2">Panel Information</Typography>
        <Paper variant="outlined" square>
          <Stack spacing={2.5} sx={{ p: 2 }}>
            <Typography component="span" variant="h2">
              {selectedPanel?.product}
            </Typography>
            <Stack direction="row" spacing={1}>
              <Stack direction="row" spacing={0.5} sx={{ alignItems: 'center' }}>
                <Typography component="span" variant="body1">{`£${selectedPanel?.price?.toFixed(2)}`}</Typography>
                <Typography component="span" variant="body1" sx={{ color: theme.palette.grey[600] }}>
                  x
                  {' '}
                  { numberOfPanels ?? 0 }
                </Typography>
              </Stack>
              { getPanelChip(selectedPanel) }
            </Stack>
            <ul style={{ paddingInlineStart: '20px', listStyleType: 'disc' }}>
              <li><Typography component="span" variant="body1">{`Installed Capacity: ${installedCapacity ?? 0} kW`}</Typography></li>
              <li><Typography component="span" variant="body1">{`Estimated Annual Output: ${estimatedAnnualOutput ?? 0} kWh`}</Typography></li>
              { selectedPanel?.description
           && (
           <Typography variant="body1" component="span">
             {
              notNilOrEmpty(selectedPanel?.description)
                ? selectedPanel?.description
                : ''
            }
           </Typography>
           )}
            </ul>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<RepeatIcon />}
              sx={{ width: 150 }}
              onClick={() => setChangePanelModalIsOpen(true)}
            >
              Change panel
            </Button>
          </Stack>
        </Paper>
        {
      changePanelModalIsOpen
        && (
          <ChangePanelList
            setChangePanelSelected={setChangePanelModalIsOpen}
            brandsData={brandsData}
            uniqueProductsData={uniqueProductsData}
            setBrandSelected={setBrandSelected}
            brandSelected={brandSelected}
            formik={formik}
          />
        )
      }
      </Stack>
    </form>
  );
}

export default PanelInfoChangePanel;
