/* eslint-disable import/prefer-default-export */
import {
  Application, Container, Graphics, Text, TextStyle
} from 'pixi.js';
import { RoofDto } from 'providers/api';
import { centimetresToMillimetres } from 'utils/dimensionConversions';
import getDimensions, { stageHeight, stageWidth } from './getDimensions';

export const drawVector = (app: Application, origin: [number, number], vector: number[][]) => {
  const newGraphic = new Graphics();
  newGraphic.beginFill(0x00000040);
  newGraphic.lineStyle(1, 0x000000, 0.25);
  newGraphic.moveTo(origin[0], origin[1]);
  vector.shift();
  let currentPoint = [origin[0], origin[1]];
  vector.forEach((vectorPoint) => {
    currentPoint = [currentPoint[0] + vectorPoint[0], currentPoint[1] + vectorPoint[1]];
    newGraphic.lineTo(currentPoint[0], currentPoint[1]);
  });
  newGraphic.closePath();
  app.stage.addChild(newGraphic);
};

export function createLabels(app: Application, solarPanelsContainer: Container, currentRoof: RoofDto) {
  const {
    numberOfPanelsAcross,
    numberOfPanelsDown,
    scaleFactor,
    totalRoofHeight,
    totalRoofWidth,
    panelBaseDimensionYWithMargin,
    panelBaseDimensionXWithMargin,
    roofTileContainerWidth,
    roofTileContainerHeight,
  } = getDimensions(currentRoof);

  // TEXT
const roofHeaderTextStyle: TextStyle = new TextStyle({
  fontFamily: 'Roboto',
  align: 'center',
  fontSize: 28,
  fontWeight: '400',
  fill: '#000000',
  stroke: '#000000',
});

const roofPanelDimensionsTextStyle: TextStyle = new TextStyle({
  fontFamily: 'Roboto',
  align: 'right',
  fontSize: scaleFactor > 1 ? 22 : 16,
  fontWeight: '400',
  fill: '#000000',
  stroke: '#000000',
});

const totalRoofDimensionsTextStyle: TextStyle = new TextStyle({
  fontFamily: 'Roboto',
  align: 'center',
  fontSize: 22,
  fontWeight: '400',
  fill: '#000000',
  stroke: '#000000',
});

const getTextStyle = (textStyle: 'roofHeaderTextStyle' | 'roofDimensionsTextStyle' | 'totalRoofDimensionsTextStyle') => {
  switch (textStyle) {
    case 'roofHeaderTextStyle':
      return roofHeaderTextStyle;
    case 'roofDimensionsTextStyle':
      return roofPanelDimensionsTextStyle;
    case 'totalRoofDimensionsTextStyle':
      return totalRoofDimensionsTextStyle;
    default:
      return roofPanelDimensionsTextStyle;
  }
};

const createText = (
  application: Application,
  text: string,
  style: 'roofHeaderTextStyle' | 'roofDimensionsTextStyle' | 'totalRoofDimensionsTextStyle',
  xPos: number,
  yPos: number,
  angle?: number,
) => {
  const newText: Text = new Text(
    text,
    getTextStyle(style),
  );
  application.stage.addChild(newText);
  newText.x = xPos;
  newText.y = yPos;
  // eslint-disable-next-line no-unused-expressions
  angle && (newText.angle = angle);
  newText.anchor.set(0.5, 0.5);
};

  // GRAPHICS / VECTORS
  const upArrow = [[0, -3], [2, 3], [-6, 0], [3, -4]];
  const downArrow = [[0, 3], [2, -3], [-6, 0], [3, 4]];
  const leftArrow = [[-3, 0], [4, -3], [0, 6], [-4, -3]];
  const rightArrow = [[3, 0], [-4, -3], [0, 6], [4, -3]];
  const roofRidgeTopLine = [[], [roofTileContainerWidth, 0]];
  const roofHeightLeftLine = [[], [0, roofTileContainerHeight]];

  // Initial top left Y dimension text
  const yPosOfFirstSolarPanelRowFromRoof = (
    totalRoofHeight - (solarPanelsContainer.height / scaleFactor)
  ) / 2;

  const firstYDimensionText: Text = new Text(
    `${Math.floor(centimetresToMillimetres(yPosOfFirstSolarPanelRowFromRoof))}`,
    roofPanelDimensionsTextStyle,
  );

  app.stage.addChild(firstYDimensionText);
  const yPosOfFirstSolarPanelRowFromStage = (stageHeight - solarPanelsContainer.height) / 2;
  firstYDimensionText.anchor.set(1.0, 0.0);
  firstYDimensionText.x = ((stageWidth - solarPanelsContainer.width) / 2) - 5;
  firstYDimensionText.y = (yPosOfFirstSolarPanelRowFromStage) - 8;

  // subsequent Y dimension text
  const rowArray = new Array(numberOfPanelsDown).fill(0);

  rowArray.map(
    (tile, index) => {
      const subsequentYDimensions = yPosOfFirstSolarPanelRowFromRoof
        + (panelBaseDimensionYWithMargin * (index + 1));

      const subsequentYDimensionText: Text = new Text(
        `${Math.floor(centimetresToMillimetres(subsequentYDimensions))}`,
        roofPanelDimensionsTextStyle,
      );
      app.stage.addChild(subsequentYDimensionText);
      subsequentYDimensionText.anchor.set(1.0, 0.0);
      subsequentYDimensionText.x = ((stageWidth - solarPanelsContainer.width) / 2) - 5;
      subsequentYDimensionText.y = yPosOfFirstSolarPanelRowFromStage + (
        (((index + 1) * panelBaseDimensionYWithMargin) * scaleFactor) - 10
      );

      return subsequentYDimensionText;
    },
  );

  // Initial bottom left X dimension text
  const xPosOfFirstSolarPanelRowFromRoof = (
    (totalRoofWidth - (solarPanelsContainer.width / scaleFactor)) / 2
  );
  const firstXDimensionText: Text = new Text(
    `${Math.floor(centimetresToMillimetres(xPosOfFirstSolarPanelRowFromRoof))}`,
    roofPanelDimensionsTextStyle,
  );
  if (scaleFactor < 0.5) {
    firstXDimensionText.angle = 270;
    firstXDimensionText.anchor.set(1.0, 0.0);
  }

  app.stage.addChild(firstXDimensionText);
  const xPosOfFirstSolarPanelRowFromStage = (stageWidth - solarPanelsContainer.width) / 2;
  firstXDimensionText.x = xPosOfFirstSolarPanelRowFromStage - 12;
  firstXDimensionText.y = (stageHeight - (stageHeight - solarPanelsContainer.height) / 2) + 5;

  // Subsequent X dimension text
  const columnArray = new Array(numberOfPanelsAcross).fill(0);
  columnArray.map(
    (tile, index) => {
      const subsequentXDimensions = xPosOfFirstSolarPanelRowFromRoof
        + (panelBaseDimensionXWithMargin * (index + 1));

      const subsequentXDimensionText: Text = new Text(
        `${Math.floor(centimetresToMillimetres(subsequentXDimensions))}`,
        roofPanelDimensionsTextStyle,
      );
      if (scaleFactor < 0.5) {
        subsequentXDimensionText.angle = 270;
        subsequentXDimensionText.anchor.set(1, 0);
      }
      app.stage.addChild(subsequentXDimensionText);
      subsequentXDimensionText.x = (
        (
          xPosOfFirstSolarPanelRowFromStage
          + (index + 1)
          * panelBaseDimensionXWithMargin
          * scaleFactor
          ) - (scaleFactor < 0.5 ? 8 : 20)
      );

      subsequentXDimensionText.y = (
        stageHeight - (stageHeight - solarPanelsContainer.height)
        / 2
      ) + (scaleFactor < 0.5 ? 5 : 5);
      return subsequentXDimensionText;
    },
  );

  return {
    upArrow, downArrow, leftArrow, rightArrow, roofRidgeTopLine, roofHeightLeftLine, createText,
  };
}
