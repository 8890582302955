import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import React from 'react';

interface ConfiguratorStageProps {
  mdUp: boolean
  triggerFadeIn: boolean
  pixiElement: React.RefObject<HTMLDivElement>
}

function ConfiguratorStage({ mdUp, triggerFadeIn, pixiElement }: ConfiguratorStageProps) {
  return (
      mdUp
        ? (
          <Box sx={{ margin: { lg: 2 }, width: { md: '100%', lg: '67%' } }}>
            <Fade
              in={triggerFadeIn}
              timeout={{ enter: 1000 }}
            >
              <Paper variant="outlined" square>
                <Box
                  id="pixi-stage"
                  ref={pixiElement}
                  component="div"
                  sx={{
                    aspectRatio: 1.4736842105263157,
                    width: '100%',
                    minWidth: (themeParam) => themeParam.breakpoints.values.md,
                    '& canvas': {
                      width: '100% !important',
                      height: '100% !important',
                    },
                  }}
                />
              </Paper>
            </Fade>
          </Box>
        )
        : (
          <Alert
            severity="info"
            sx={{ width: '100%' }}
          >
            To see a visual of your roof, please view on a wider screen.
          </Alert>
        )
  );
}

export default ConfiguratorStage;
