import { Box, Typography, useMediaQuery } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import SelectionTile from 'components/SelectionTile';
import { FormikHelpers, FormikState } from 'formik';
import React from 'react';
import { ConfigSelectionTile } from 'utils/types';

export type SelectionTileListProps = {
  data: ConfigSelectionTile[]
  questionTitle?: string
  setFieldValue?: FormikHelpers<any>['setFieldValue']
  values?: FormikState<any>['values']
}
function SelectionTileList({
  data, questionTitle, setFieldValue, values,
}: SelectionTileListProps) {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  // const xlUp = useMediaQuery(theme.breakpoints.up('xl'));

  return (
    <Stack
      spacing={2}
      direction="column"
      sx={{ width: '100%' }}
    >
      <Typography
        sx={{
          typography: { xs: 'body1', sm: 'h2' },
          mb: 1,
        }}
      >
        {questionTitle}
      </Typography>
      <Stack
        alignSelf="flex-start"
        spacing={smUp ? 2 : 0}
        direction="row"
        sx={{ width: '100%' }}
      >
        {
          data.map((tile) => (
            <Box
              key={tile.id}
              sx={{
                px: smUp ? 1 : 0.5,
                width: '100%',
                visibility: tile.hidden ? 'hidden' : 'visible',
              }}
            >
              <SelectionTile
                key={tile.id}
                label={tile.label}
                field={tile.field}
                value={tile.value}
                buttonIcon={tile.buttonIcon}
                buttonAction={tile.buttonAction}
                setFieldValue={setFieldValue}
                values={values}
                recommendedChip={tile.recommendedChip}
              />
            </Box>
          ))
        }
      </Stack>
    </Stack>
  );
}
export default SelectionTileList;
