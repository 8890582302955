import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ActionButtons from 'components/ActionButtons';
import SelectionTileList from 'components/SelectionTileList';
import { useFormik } from 'formik';
import { roofSizeValidationInMetres } from 'modules/Configurator/ConfiguratorGrid/getDimensions';
import {
  AddRoofDto,
  MountType, PanelDto, PanelStockStatus, RoofPanelGridOrientation, RoofType
} from 'providers/api';
import { useListAllProductsQuery, useListBrandsQuery } from 'providers/api/usePanels';
import React, { useState } from 'react';
import { IJOPOWER_CONTACT_NUMBER } from 'utils/const';
import getPanelChip from 'utils/getPanelChip';
import { ConfigSelectionTile, Nullable } from 'utils/types';
import {
  mixed, number,
  object,
  SchemaOf,
  string
} from 'yup';

const addRoofCommandSchema: SchemaOf<AddRoofDto> = object().shape({
  name: string().required('Please enter a roof name'),
  heightInMetres: number()
    .required('Please enter your roof height')
    .typeError('Height must be a number')
    .min(
      roofSizeValidationInMetres.height.min,
      `Please enter a height of ${roofSizeValidationInMetres.height.min} metres or more`,
    )
    .max(
      roofSizeValidationInMetres.height.max,
      `Please enter a height of ${roofSizeValidationInMetres.height.max} metres or less. If you have a bigger roof,
      please contact us on ${IJOPOWER_CONTACT_NUMBER}`,
    ),
  widthInMetres: number()
    .required('Please enter your roof width')
    .typeError('Width must be a number')
    .min(
      roofSizeValidationInMetres.width.min,
      `Please enter a width of ${roofSizeValidationInMetres.width.min} metres or more`,
    )
    .max(
      roofSizeValidationInMetres.width.max,
      `Please enter a width of ${roofSizeValidationInMetres.width.max} metres or less. If you have a bigger roof,
      please contact us on ${IJOPOWER_CONTACT_NUMBER}`,
    ),
  mountType: mixed<MountType>()
    .required()
    .test((mountTypeValue) => (
      mountTypeValue === MountType.InRoof
      || mountTypeValue === MountType.OnRoof
    )),
  roofType: mixed<RoofType>()
    .required()
    .test((roofTypeValue) => roofTypeValue === RoofType.Slate
      || roofTypeValue === RoofType.Tile
      || roofTypeValue === RoofType.Corrugated)
    .when(['mountType'], {
      is: (mountType: any) => mountType === MountType.InRoof,
      then: (schema) => schema.test(
        'if mountType is corrugated, roofType cannot be InRoof - is this valid?',
        'if your mount type is corrugated, your roof type cannot be in roof',
        (roofType) => roofType !== RoofType.Corrugated,
      ),
    }),
  panelSku: string().notRequired(),
  panelOrientation: mixed<RoofPanelGridOrientation>().notRequired(),
});

type IAddRoofForm = Nullable<AddRoofDto>

type ManageRoofFormProps = {
  onFormSubmit: (form: AddRoofDto) => void
  mountTypeTileArray: ConfigSelectionTile[]
  roofTypeTileArray: ConfigSelectionTile[]
  setInRoofandCorrugatedSelected: (selection: boolean) => void
}

function AddRoofForm({ onFormSubmit, mountTypeTileArray, roofTypeTileArray, setInRoofandCorrugatedSelected }: ManageRoofFormProps) {
  const {
    handleSubmit, setFieldValue, values, isValid, handleChange, handleBlur, errors, touched,
  } = useFormik<IAddRoofForm>({
    initialValues: {
      name: '',
      heightInMetres: null,
      widthInMetres: null,
      mountType: null,
      roofType: null,
      panelSku: '',
      panelOrientation: null,
    },
    onSubmit: (form, { setSubmitting }) => {
      setSubmitting(true);
      const manageRoofForm = addRoofCommandSchema.validateSync(form);
      onFormSubmit(manageRoofForm);
    },
    validationSchema: addRoofCommandSchema,
    validateOnMount: true,
  });
  const [wantPreferredPanel, setWantPreferredPanel] = useState('false');
  const [brandSelected, setBrandSelected] = useState('');
  const [panelSelected, setPanelSelected] = useState<PanelDto | null>();

  const { data: brandsData } = useListBrandsQuery();
  const { data: productsData } = useListAllProductsQuery(
    { brand: brandSelected },
    { enabled: !!brandSelected },
  );

  const filterOutOver370wPanelsIfMountTypeInRoof = () => {
    if (values.mountType === MountType.InRoof) {
      return productsData?.filter((product) => (product.ratedPower ? product.ratedPower <= 370 : product));
    } return productsData;
  };

  const handleWantPreferredPanelChange = (event: SelectChangeEvent) => {
    setWantPreferredPanel(event.target.value);
    if (event.target.value === 'false') {
      setBrandSelected('');
      setFieldValue('panelSku', '');
      setPanelSelected(null);
    }
  };

  React.useEffect(() => {
    if (values.mountType === MountType.InRoof && values.roofType === RoofType.Corrugated) {
      setInRoofandCorrugatedSelected(true);
    } else setInRoofandCorrugatedSelected(false);
  }, [values.mountType, values.roofType]);

  // Resets preferred panel details if in roof is selected as only panels over 370w can be selected
  React.useEffect(() => {
    if (values.mountType === MountType.InRoof && (panelSelected?.ratedPower && panelSelected?.ratedPower > 370)) {
      setBrandSelected('');
      setFieldValue('panelSku', '');
      setPanelSelected(null);
    }
  }, [values.mountType]);

  return (
    <form style={{ width: '100%' }} onSubmit={handleSubmit}>
      <Typography
        sx={{
          typography: { xs: 'h6', sm: 'h1' },
          mb: { xs: 2, sm: 4 },
        }}
      >
        Add a roof to your configuration
      </Typography>
      <Stack direction="column" spacing={2}>
        <Stack spacing={2} direction="column" justifyContent="space-between">
          <Box sx={{ width: '100%' }}>
            <Typography
              sx={{
                typography: { xs: 'body1', sm: 'h2' },
                mb: 1,
              }}
            >
              Roof name
            </Typography>
            <TextField
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name ?? ''}
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
              sx={{
                width: { xs: '100%', xl: 'calc(50% - 10px)' },
              }}
              placeholder="i.e  South Roof"
            />
          </Box>
          <Stack spacing={2.5} direction="row" justifyContent="space-between">
            <Box sx={{ width: '100%' }}>
              <Typography
                sx={{
                  typography: { xs: 'body1', sm: 'h2' },
                  mb: 1,
                }}
              >
                Roof height
              </Typography>
              <TextField
                name="heightInMetres"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.heightInMetres ?? ''}
                error={touched.heightInMetres && Boolean(errors.heightInMetres)}
                helperText={
                  touched.heightInMetres && errors.heightInMetres
                    ? errors.heightInMetres
                    : 'Distance from gutter to ridge tile'
                }
                InputProps={{
                  endAdornment: <InputAdornment position="end">Metres</InputAdornment>,
                }}
                fullWidth
                type="number"
              />
            </Box>
            <Box sx={{ width: '100%' }}>
              <Typography
                sx={{
                  typography: { xs: 'body1', sm: 'h2' },
                  mb: 1,
                }}
              >
                Roof length
              </Typography>
              <TextField
                name="widthInMetres"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.widthInMetres ?? ''}
                error={touched.widthInMetres && Boolean(errors.widthInMetres)}
                helperText={
                  touched.widthInMetres && errors.widthInMetres
                    ? errors.widthInMetres
                    : 'Length of the gutter'
                }
                InputProps={{
                  endAdornment: <InputAdornment position="end">Metres</InputAdornment>,
                }}
                fullWidth
                type="number"
              />
            </Box>
          </Stack>
        </Stack>
        <Alert severity="info">
          Unsure about how to measure your roof?
          If you do not have your roof measurements, you can get approximate measurements via Google Earth.
          {' '}
          <Link
            underline="none"
            href="https://www.ijopower.com/how-to-measure-your-roof/"
            target="_blank"
          >
            Click here
          </Link>
          {' '}
          to see how.
        </Alert>
        <Stack spacing={2}>
          <SelectionTileList
            questionTitle="How would you like to mount your panels?"
            data={mountTypeTileArray}
            setFieldValue={setFieldValue}
            values={values}
          />
          <SelectionTileList
            questionTitle="Roof type"
            data={roofTypeTileArray}
            setFieldValue={setFieldValue}
            values={values}
          />
        </Stack>
        <Box sx={{ width: '100%' }}>
          <Typography
            sx={{
              typography: { xs: 'body1', sm: 'h2' },
              mb: 1,
            }}
          >
            Finally do you have a preferred solar panel?
          </Typography>
          <FormControl
            fullWidth
          >
            <Select
              value={wantPreferredPanel}
              onChange={handleWantPreferredPanelChange}
              fullWidth
            >
              <MenuItem value="true">Yes</MenuItem>
              <MenuItem value="false">No</MenuItem>
            </Select>
          </FormControl>
        </Box>
        {
          wantPreferredPanel === 'true'
          && (
            <Box sx={{ width: '100%' }}>
              {values.mountType === MountType.InRoof
                && (
                  <Alert severity="info" sx={{ marginBottom: 2 }}>
                    In Roof mounting cannot have panels of more than 370w rated power, so we&apos;ve only displayed panels of 370W and below.
                  </Alert>
                )}
              <Typography
                sx={{
                  typography: { xs: 'body1', sm: 'h2' },
                  mb: 1,
                }}
              >
                Please select your preferred manufacturer
              </Typography>
              <FormControl
                fullWidth
              >
                <Select
                  value={brandSelected ?? ''}
                  onChange={(event) => {
                    setBrandSelected(event.target.value);
                    setPanelSelected(null);
                  }}
                  displayEmpty
                  fullWidth
                >
                  {
                    brandsData && brandsData.map((brand) => (
                      <MenuItem
                        value={brand.name}
                        key={brand.name}
                      >
                        {brand.name}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Box>
          )
        }
        {(brandSelected || values.panelSku) && wantPreferredPanel === 'true'
          && (
            <Box sx={{ width: '100%' }}>
              <Typography
                sx={{
                  typography: { xs: 'body1', sm: 'h2' },
                  mb: 1,
                }}
              >
                Please select your prefered panel
              </Typography>
              <Autocomplete
                value={panelSelected}
                options={filterOutOver370wPanelsIfMountTypeInRoof() || []}
                id="panelSku"
                isOptionEqualToValue={(option, value) => option.sku === value.sku}
                onChange={(event, newPanel) => {
                  setFieldValue('panelSku', newPanel?.sku ?? '');
                  setPanelSelected(newPanel);
                }}
                fullWidth
                getOptionLabel={(option: PanelDto) => (option.product ? option.product : '')}
                getOptionDisabled={(option) => option.panelStockStatus === PanelStockStatus.OutOfStock}
                renderOption={(props, panelProduct: PanelDto) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <Box component="li" {...props} sx={{ p: 2 }} key={panelProduct.sku}>
                    <Stack spacing={1}>
                      <Typography variant="h2">
                        {panelProduct.product}
                      </Typography>
                      <Stack direction="row" spacing={1}>
                        <Typography variant="body1">
                          &pound;
                          {panelProduct.price}
                        </Typography>
                        {getPanelChip(panelProduct)}
                      </Stack>
                    </Stack>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    id="panelSku"
                    name="panelSku"
                    variant="outlined"
                    onBlur={handleBlur}
                    fullWidth
                  />
                )}
              />
            </Box>
          )}
      </Stack>
      <ActionButtons
        isValid={isValid}
        backPath={-1}
      />
    </form>
  );
}

export default AddRoofForm;
