import ArrowForward from '@mui/icons-material/ArrowForward';
import { SelectChangeEvent } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import {
  PanelBrandDto, PanelDto, RoofDto, UpdatePanelGridOrientationForm, UpdateRoofPanelForm
} from 'providers/api';
import { PanelInformation as PanelInfoChangePanel, PanelOrientation, RoofSelection } from './index';

interface ConfiguratorFormProps {
  listRoofsData: RoofDto[]
  brandsData: PanelBrandDto[]
  uniqueProductsData: PanelDto[]
  brandSelected: string
  setBrandSelected: (brand: string) => void
  currentRoof?: RoofDto
  onChangePanelFormSubmit: (form: UpdateRoofPanelForm) => void
  onUpdatePanelOrientationSubmit: (data: UpdatePanelGridOrientationForm) => void
  numberOfPanels?: number
  installedCapacity?: number
  estimatedAnnualOutput?: number
  onNextSubmit: () => void
  onRoofSelection: (event: SelectChangeEvent<string>) => void
  orientationMutationIsProcessing: boolean
}

function ConfiguratorForm({
  listRoofsData, brandsData, uniqueProductsData, brandSelected,
  currentRoof, setBrandSelected, onChangePanelFormSubmit,
  onUpdatePanelOrientationSubmit, numberOfPanels, installedCapacity,
  estimatedAnnualOutput, onNextSubmit, onRoofSelection, orientationMutationIsProcessing,
}: ConfiguratorFormProps) {
  return (

    <Stack
      spacing={3}
      height="100%"
      justifyContent="center"
    >
      { currentRoof
        && (
        <>
          <RoofSelection
            listRoofsData={listRoofsData ?? []}
            currentRoof={currentRoof}
            onRoofSelection={onRoofSelection}
          />
          <PanelOrientation
            currentRoof={currentRoof}
            onUpdatePanelOrientationSubmit={onUpdatePanelOrientationSubmit}
            orientationMutationIsProcessing={orientationMutationIsProcessing}
          />
          <Alert severity="info">
            Our design tool allows for a minimum 200mm border.

            You may click on a panel to remove it from the design, to reflect a toilet stack, Velux window or chimney.

            The green border allows for a 30mm gap between the panels.

            All dimensions are presented in millimetres.

          </Alert>
          <PanelInfoChangePanel
            currentRoof={currentRoof}
            onChangePanelFormSubmit={onChangePanelFormSubmit}
            brandsData={brandsData}
            uniqueProductsData={uniqueProductsData}
            brandSelected={brandSelected}
            setBrandSelected={setBrandSelected}
            numberOfPanels={numberOfPanels}
            installedCapacity={installedCapacity}
            estimatedAnnualOutput={estimatedAnnualOutput}
          />
          <Box
            sx={{
                          display: 'flex',
                          width: '100%',
                          marginTop: 3,
                          marginBottom: {
                            xs: '64px !important',
                            sm: 0,
                          },
                        }}
            justifyContent="flex-end"
          >
            <Button
              endIcon={<ArrowForward />}
              variant="contained"
              onClick={() => onNextSubmit()}
              disabled={
                installedCapacity === 0
                || !installedCapacity
                || !estimatedAnnualOutput
                || estimatedAnnualOutput === 0
              }
            >
              Next
            </Button>
          </Box>
        </>
        )}
    </Stack>
  );
}

export default ConfiguratorForm;
