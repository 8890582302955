import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

type FormikActionsProps = {
  isValid?: boolean
  hideBackButton?: boolean
  backPath?: string | -1
  submitText?: string
  navigateOnlyNoSubmitPath?: string
}

function ActionButtons({
  isValid, hideBackButton, backPath, submitText = 'Next', navigateOnlyNoSubmitPath,
}: FormikActionsProps) {
  const navigate = useNavigate();

  const onBackNavigation = () => {
    if (!backPath) return;
    navigate(backPath as any);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        marginTop: 3,
        marginBottom: {
          xs: '64px !important',
          sm: 0,
        },
      }}
      justifyContent={hideBackButton ? 'flex-end' : 'space-between'}
    >
      {!hideBackButton
        && (
          <Button
            startIcon={<ArrowBack />}
            variant="text"
            onClick={() => onBackNavigation()}
          >
            Back
          </Button>
        )}
      {
        navigateOnlyNoSubmitPath
          ? (
            <Button
              endIcon={<ArrowForward />}
              variant="contained"
              onClick={() => navigate(navigateOnlyNoSubmitPath)}
            >
              {submitText}
            </Button>
          )
          : (
            <Button
              endIcon={<ArrowForward />}
              variant="contained"
              type="submit"
              disabled={!isValid}
            >
              {submitText}
            </Button>
          )
      }

    </Box>
  );
}

export default ActionButtons;
