/* eslint-disable no-param-reassign */
/* eslint-disable prefer-const */
import { Application, Container } from 'pixi.js';
import {
  PanelFrameType, PanelGridRowDto, RoofDto, RoofPanelGridOrientation
} from 'providers/api';
import React from 'react';
import drawRoof from './drawRoof';
import { stageHeight, stageWidth } from './getDimensions';
import makeInteractive from './makeInteractive';

export interface StageChildrenProps {
  solarPanelBaseContainer?: Container,
  individualWireContainer?: Container,
  emptyPanelWithPlusBaseContainer?: Container,
}

interface PixiRoofInternalStates {
  orientation?: RoofPanelGridOrientation,
  panelGrid?: PanelGridRowDto[]
  frameType?: PanelFrameType
}

interface UsePixiProps {
  currentRoof: RoofDto | undefined
  pixiElement: HTMLDivElement | null;
  onUpdatePanelGrid?: (gridRows: PanelGridRowDto[]) => void,
  takeScreenshotOfEachRoof?: (data: Blob[]) => void,
  interactive?: boolean
  stageIsLoading?: React.MutableRefObject<Boolean>
}

const usePixi = ({
  currentRoof, pixiElement, onUpdatePanelGrid, interactive = true, stageIsLoading,
}: UsePixiProps) => {
  const [fontsLoaded, setFontsLoaded] = React.useState(false);
  const cellPanelStates = React.useRef<PanelGridRowDto[]>();
  document.fonts.ready.then(() => setFontsLoaded(true));

  // Global variables
  let app: Application;
  let panelGrid: PanelGridRowDto[] = [];
  let internalState: PixiRoofInternalStates = {
    orientation: undefined,
    panelGrid: undefined,
    frameType: undefined,
  };

  const clearStage = (stageChildren: StageChildrenProps) => {
    // Check and remove children
      while (app?.stage.children[0]) {
        app.stage.removeChild(app.stage.children[0]);
        stageChildren = {
          solarPanelBaseContainer: undefined,
          individualWireContainer: undefined,
          emptyPanelWithPlusBaseContainer: undefined,
        };
      }
      return stageChildren;
    };

      // compare internal and external state
  const reset = (
    roof: RoofDto,
  ) => {
    const {
      solarPanelBaseContainer,
      individualWireContainer,
      emptyPanelWithPlusBaseContainer,
    } = drawRoof({
      app,
      currentRoof: roof,
      onUpdatePanelGrid,
      panelGrid,
    });
    // eslint-disable-next-line consistent-return
    return {
      solarPanelBaseContainer,
      individualWireContainer,
      emptyPanelWithPlusBaseContainer,
    };
  };

  const loop = (
    roof: RoofDto,
    stageChildren: StageChildrenProps,
  ) => {
    if (!panelGrid) throw new Error('No panel grid row in loop');
    if (
      internalState.orientation !== currentRoof?.panelOrientation
      || internalState.panelGrid !== currentRoof?.panelGridRows
      || internalState.frameType !== currentRoof?.panel?.frameType
    ) {
      clearStage(stageChildren);
      reset(roof);
    }
    makeInteractive({
      currentRoof: currentRoof ?? {} as any,
      panelGrid,
      stageChildren,
    });
    // eslint-disable-next-line no-param-reassign
    internalState = {
      orientation: currentRoof?.panelOrientation,
      panelGrid: currentRoof?.panelGridRows,
      frameType: currentRoof?.panel?.frameType,
    };
  };

  const init = (roof: RoofDto | undefined = currentRoof) => {
    app = new Application({
      resolution: window.devicePixelRatio || 1,
      autoDensity: true,
      backgroundColor: 0xFFFFFF, // change to view boundaries: 0x012b30
      width: stageWidth,
      height: stageHeight,
      antialias: true,
      // add to view boundaries: backgroundAlpha: 0.4,
    });
    if (!app) throw new Error("app didn't load for init");
    if (!roof) throw new Error("roof didn't load for init");

    pixiElement?.appendChild(app.view);
    app.start();

    // To stop re-render which causes the app to reload on panel click,
    // we assign panelGridRows to a variable and use this
    panelGrid = roof.panelGridRows ?? [];
    if (!panelGrid) throw new Error('No panel grid');

    internalState = {
      orientation: roof.panelOrientation,
      panelGrid: roof.panelGridRows,
      frameType: roof.panel?.frameType,
    };
    // Experienced a stale closure when passing callbacks to the ticker.
    // React docs suggest ref to get around this.
    // Please leave unless there's a better solution.
    cellPanelStates.current = roof?.panelGridRows;

    let stageChildren: StageChildrenProps = {
      solarPanelBaseContainer: undefined,
      individualWireContainer: undefined,
      emptyPanelWithPlusBaseContainer: undefined,
    };

    const {
      solarPanelBaseContainer,
      individualWireContainer,
      emptyPanelWithPlusBaseContainer,
    } = reset(roof);

    stageChildren = {
      solarPanelBaseContainer,
      individualWireContainer,
      emptyPanelWithPlusBaseContainer,
    };
    if (stageIsLoading) stageIsLoading.current = false;
    if (interactive) {
      app?.ticker.add(() => loop(
        roof,
        stageChildren,
      ));
    }
    return { startedApp: app, appStage: app.stage };
  };
  // Main useEffect that starts & destroys the app
  React.useEffect(() => {
    if (stageIsLoading) stageIsLoading.current = true;
    if (pixiElement && currentRoof && fontsLoaded && currentRoof.panelGridRows && currentRoof.panelGridRows.length > 0) init();

    return () => app?.destroy(true, true);
  }, [pixiElement, currentRoof, fontsLoaded]);

  return {
    reset,
  };
};

export default usePixi;
