export const LOCAL_STORAGE_PREFIX = 'IJO_POWER';
export const PAGINATION_PAGE_SIZES = [10, 25, 50];
export const PAGINATION_DEFAULT_PAGE_SIZE = PAGINATION_PAGE_SIZES[0];
export const AUTOCOMPLETE_PAGE_SIZE = PAGINATION_PAGE_SIZES[1];
export const BAD_REQUEST_CODE = 400;
export const UNAUTHORISED_ERROR_CODE = 401;
export const FORBIDDEN_ERROR_CODE = 403;
export const NOT_FOUND_ERROR_CODE = 404;
export const NOT_ACCEPTABLE_ERROR_CODE = 406;
export const TIMEOUT_ERROR_CODE = 408;
export const TEMPORARY_ACCESS_TOKEN_EXPIRED_CODE = 410;
export const EXPECTED_ERROR_CODES = [
  BAD_REQUEST_CODE,
  UNAUTHORISED_ERROR_CODE,
  NOT_FOUND_ERROR_CODE,
  NOT_ACCEPTABLE_ERROR_CODE,
  FORBIDDEN_ERROR_CODE,
  TEMPORARY_ACCESS_TOKEN_EXPIRED_CODE,
];
export const SECOND_IN_MILLISECONDS = 1000;
export const MINUTE_IN_MILLISECONDS = SECOND_IN_MILLISECONDS * 60;
export const HOUR_IN_MILLISECONDS = MINUTE_IN_MILLISECONDS * 60;
export const DAY_IN_MILLISECONDS = HOUR_IN_MILLISECONDS * 24;
export const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
