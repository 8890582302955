import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { deleteFromStorage } from '@rehooks/local-storage';
import { LOCAL_STORAGE_PREFIX } from 'config';
import React from 'react';

function Redirect() {
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    deleteFromStorage(`${LOCAL_STORAGE_PREFIX}_configurationId`);
    deleteFromStorage(`${LOCAL_STORAGE_PREFIX}_currentSelectedRoofId`);
    const timer = setTimeout(() => {
      setShow(true);
    }, 300);
    window.location.href = 'https://www.ijopower.com/your-estimate-has-been-sent/';
    return () => clearTimeout(timer);
  }, []);

  return (
    <Box
      sx={{
        width: '50%',
        maxWidth: '300px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      {show && (<CircularProgress size="100%" />)}
    </Box>
  );
}

export default Redirect;
