import { Application, Container, Graphics } from 'pixi.js';
import { RoofDto } from 'providers/api';
import { isEven } from 'utils/number';
import getDimensions from './getDimensions';

const createRoofTiles = (app: Application, currentRoof: RoofDto) => {
  const {
    tileWidth,
    tileHeight,
    offSetAmount,
    tileGrid,
    numberOfTiles,
    roofTileContainerHeight,
    roofTileContainerWidth,
    roofTileContainerX,
    roofTileContainerY,
  } = getDimensions(currentRoof);

  const roofTileContainer = new Container();
  app.stage.addChild(roofTileContainer);

  const roofTileBase = new Graphics();
  roofTileBase
    .beginFill(0x989898, 1)
    .lineStyle(1, 0x000000, 0.15)
    .drawRect(
      0,
      0,
      (tileWidth * tileGrid.numberOfTilesAcross) + offSetAmount,
      tileHeight * tileGrid.numberOfTilesDown,
    )
    .endFill();

  const roofTile = new Graphics();
  const rooftileArray = new Array(numberOfTiles).fill(0);
  rooftileArray.map(
    (tile, index) => {
      const column = index % (tileGrid.numberOfTilesAcross);
      // e.g. goes 0-23 up to 24 tiles across in total then resets to 0.
      const row = Math.floor((index / tileGrid.numberOfTilesAcross));
      // e.g. 15 (360 being 360th tile which is last in index of 15 rows)
      return (
        roofTile
          .beginFill(0x989898, 1)
          .lineStyle(1, 0x000000, 0.15)
          .drawRect(
            isEven(row)
              ? (column) * tileWidth + offSetAmount
              : (column) * tileWidth,
            (row) * tileHeight,
            tileWidth,
            tileHeight,
          )
          .endFill()
      );
    },
  );

  roofTileContainer.addChild(roofTileBase);
  roofTileContainer.addChild(roofTile);
  roofTileContainer.height = roofTileContainerHeight;
  roofTileContainer.width = roofTileContainerWidth;
  roofTileContainer.x = roofTileContainerX;
  roofTileContainer.y = roofTileContainerY;
  return roofTileContainer;
};

export default createRoofTiles;
