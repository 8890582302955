// TO DO: ts settings change and remove
/* eslint-disable no-unused-vars */
import Add from '@mui/icons-material/Add';
import Tune from '@mui/icons-material/Tune';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { RoofDto } from 'providers/api';
import { useNavigate } from 'react-router-dom';

interface RoofSelectionProps {
  listRoofsData: RoofDto[]
  currentRoof: RoofDto
  onRoofSelection: (event: SelectChangeEvent<string>) => void
}

function RoofSelection({
  listRoofsData, currentRoof, onRoofSelection,
}: RoofSelectionProps) {
  const navigate = useNavigate();

  return (
    <Stack spacing={1}>
      <Typography variant="h2">Roof</Typography>
      <Select
        id="roofConfigurations"
        displayEmpty
        onChange={(event) => onRoofSelection(event)}
        value={currentRoof.roofId}
      >
        {
        listRoofsData && listRoofsData.map((roof) => (
          <MenuItem
            value={roof.roofId}
            key={roof.roofId}
          >
            {roof.name}
          </MenuItem>
        ))
      }
      </Select>
      <Stack direction="row" spacing={2}>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<Tune />}
          onClick={() => navigate(`/edit-roof/${currentRoof.roofId}`)}
        >
          Edit roof settings
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={() => navigate('/add-roof')}
        >
          Add roof
        </Button>
      </Stack>
    </Stack>
  );
}

export default RoofSelection;
