/* eslint-disable import/prefer-default-export */
import { RoofId } from '.';
import {
  CreateRoofCommand, DeleteRoofCommand, FileResponse, RoofDto, UpdateRoofCommand,
  UpdateRoofPanelForm, UpdateRoofPanelGridCommand, UpdateRoofPanelGridOrientationCommand
} from './api';
import {
  GetKey,
  ListKey, makeCreateMutation, makeDeleteMutation, makeGetQuery, makeListQuery, makeUpdateMutation
} from './useApi';

const ROOFS_KEY = 'configurations' as ListKey;
const ROOF_KEY = 'configuration' as GetKey;

export const useListRoofsQuery = makeListQuery<string, RoofDto[]>(
  ROOFS_KEY,
  (api, configurationId) => () => api.roofs.list(configurationId),
);

export interface RoofQueryParams {
  configurationId: string | null,
  roofId: string | null,
}

export const useGetRoofQuery = makeGetQuery<RoofDto, RoofQueryParams>(
  ROOF_KEY,
  (api, params) => () => api.roofs.get(params.configurationId, params.roofId),
);

export interface CreateRoofMutation {
  configurationId: string,
  createRoofForm: CreateRoofCommand
}

export const useCreateRoofMutation = makeCreateMutation<RoofId, CreateRoofMutation>(
  ROOFS_KEY,
  (api) => (mutation) => api.roofs.create(mutation.configurationId, mutation.createRoofForm),
);

export interface UpdateRoofMutation {
  roofId: string
  command: UpdateRoofCommand
}

export const useUpdateRoofMutation = makeUpdateMutation<FileResponse, UpdateRoofMutation>(
  ROOF_KEY,
  ROOFS_KEY,
  (api, configurationId) => (mutation) => api.roofs.update(configurationId, mutation.roofId, mutation.command),
);

export interface UpdatePanelMutation {
  roofId: string
  command: UpdateRoofPanelForm
}

export const useUpdatePanel = makeUpdateMutation<FileResponse, UpdatePanelMutation>(
  ROOF_KEY,
  ROOFS_KEY,
  (api, configurationId) => (mutation) => api.roofs.updatePanel(configurationId, mutation.roofId, mutation.command),
);

export interface UpdatePanelGridMutation {
  roofId: string
  command: UpdateRoofPanelGridCommand
}

export const useUpdatePanelGrid = makeUpdateMutation<FileResponse, UpdatePanelGridMutation>(
  ROOF_KEY,
  ROOFS_KEY,
  (api, configurationId) => (mutation) => api.roofs.updatePanelGrid(configurationId, mutation.roofId, mutation.command),
);

export interface UpdatePanelGridOrientationMutation {
  roofId: string
  command: UpdateRoofPanelGridOrientationCommand
}

export const useUpdatePanelGridOrientation = makeUpdateMutation<FileResponse, UpdatePanelGridOrientationMutation>(
  ROOF_KEY,
  ROOFS_KEY,
  (api, configurationId) => (mutation) => (
    api.roofs.updatePanelGridOrientation(configurationId, mutation.roofId, mutation.command)
  ),
);

export interface DeleteRoofMutation {
  configurationId: string;
  roofId: string;
  command: DeleteRoofCommand;
}

export const useDeleteRoof = makeDeleteMutation<void, DeleteRoofMutation>(
  ROOF_KEY,
  ROOFS_KEY,
  (api) => (mutation) => api.roofs.delete(mutation.configurationId, mutation.roofId, mutation.command),
  () => 'Roof has been successfully deleted',
  (mutation) => mutation.configurationId,
  [ROOF_KEY],
);
