/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable no-return-assign */
import AddIcon from 'assets/AddIconWhite.svg';
import {
  Container, Rectangle, Sprite, Texture
} from 'pixi.js';
import { PanelGridRowDto, RoofDto } from 'providers/api';
import getDimensions, {
  stageHeight, stageWidth, totalSolarPanelMarginX, totalSolarPanelMarginY
} from './getDimensions';

const handleOnClick = (
  currentIndex: string,
  panelGrid: PanelGridRowDto[],
  onUpdatePanelGrid: (gridRows: PanelGridRowDto[]) => void,
) => {
  // 1. Create new array
  const prevSolarPanelArray: PanelGridRowDto[] = Object.values(panelGrid);
  // 2. Find index
  const newArr = prevSolarPanelArray.map(
    (prevRowObject, prevRowIndex) => prevRowObject.slots.map((prevPanel, prevColumnIndex) => {
      // 3. Match index
      const previousIndex = `(${prevRowIndex}-${prevColumnIndex})`;
      if (previousIndex === currentIndex) {
        // 4. return the opposite of current visibility on click, turning hasPanel to true or false
        return prevSolarPanelArray[prevRowIndex].slots[prevColumnIndex] = {
          ...prevSolarPanelArray[prevRowIndex].slots[prevColumnIndex],
          hasPanel:
          !prevSolarPanelArray[prevRowIndex].slots[prevColumnIndex].hasPanel,
        };
      }
      return prevSolarPanelArray[prevRowIndex].slots[prevColumnIndex] = {
        ...prevSolarPanelArray[prevRowIndex].slots[prevColumnIndex],
        hasPanel:
          prevSolarPanelArray[prevRowIndex].slots[prevColumnIndex].hasPanel,
      };
    }),
  );
  // 5. Convert back to Dto so back-end accepts it
  const arrayToObject = newArr.map((array) => ({ slots: [...array] }));
  if (!onUpdatePanelGrid) return;
  onUpdatePanelGrid(arrayToObject);
  panelGrid = arrayToObject;
};

interface createEmptyPanelWithPlusProps {
  currentRoof: RoofDto
  onUpdatePanelGrid?: (gridRows: PanelGridRowDto[]) => void,
  panelGrid: PanelGridRowDto[]
}

function createEmptyPanelWithPlus({
  currentRoof, onUpdatePanelGrid, panelGrid,
}: createEmptyPanelWithPlusProps) {
  const {
    solarPanelWidth,
    solarPanelHeight,
    panelBaseDimensionX,
    panelBaseDimensionY,
    solarPanelArray,
    numberOfPanelsAcross,
    scaleFactor,
  } = getDimensions(currentRoof);

  const emptyPanelWithPlusContainer = new Container();
  const emptyPanelWithPlusBaseContainer = new Container();

  emptyPanelWithPlusContainer.sortableChildren = true;

  const plusTexture = Texture.from(AddIcon);

  // Loop through the index of each row and column of the roof grid panel
  solarPanelArray.forEach(
    (rowObject, rowIndex) => {
      const row = rowIndex; // row is the number of objects in the PanelGridArray
      rowObject.slots.forEach((panel, columnIndex) => {
        const column = columnIndex % (numberOfPanelsAcross);
        // e.g. 0000 1111 (4 solar panels on top row, 4 on bottom row).
        const uniquePanelId = `(${rowIndex}-${columnIndex})`;

        const setXPanelWithMargin = (column * panelBaseDimensionX) + (column * totalSolarPanelMarginX);
        const setYPanelWithMargin = (row * panelBaseDimensionY) + (row * totalSolarPanelMarginY);

        const emptyPanelWithPlus = new Sprite(plusTexture);
        // Interactivity
        emptyPanelWithPlus.interactive = true;
        emptyPanelWithPlus.buttonMode = true;
        if (onUpdatePanelGrid) {
          emptyPanelWithPlus.on('pointerdown', () => {
            handleOnClick(
              uniquePanelId,
              panelGrid,
              onUpdatePanelGrid,
            );
          });
        }
        emptyPanelWithPlus.name = `emptyPanelWithPlus${uniquePanelId}`;
        // Position and dimensions
        emptyPanelWithPlus.zIndex = 3;
        emptyPanelWithPlus.anchor.set(0.5, 0.5);
        emptyPanelWithPlus.x = setXPanelWithMargin;
        emptyPanelWithPlus.y = setYPanelWithMargin;
        emptyPanelWithPlus.hitArea = new Rectangle(
          -(solarPanelWidth / 2),
          -(solarPanelHeight / 2),
          solarPanelWidth,
          solarPanelHeight,
        );

        emptyPanelWithPlusBaseContainer.addChild(emptyPanelWithPlus);
      });
    },
  );
  emptyPanelWithPlusContainer.addChild(emptyPanelWithPlusBaseContainer);
  emptyPanelWithPlusContainer.height = emptyPanelWithPlusBaseContainer.height * scaleFactor;
  emptyPanelWithPlusContainer.width = emptyPanelWithPlusBaseContainer.width * scaleFactor;
  emptyPanelWithPlusContainer.x = (stageWidth - emptyPanelWithPlusContainer.width) / 2;
  emptyPanelWithPlusContainer.y = (stageHeight - emptyPanelWithPlusContainer.height) / 2;

  return { emptyPanelWithPlusContainer, emptyPanelWithPlusBaseContainer };
}

export default createEmptyPanelWithPlus;
