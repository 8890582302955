import { createContext } from 'react';
import { ConfigurationsService, PanelsService, RoofsService } from './api';

export interface IApiContext {
  configurations: ConfigurationsService,
  roofs: RoofsService,
  panels: PanelsService,
}

const ApiContext = createContext<IApiContext | null>(null);

export default ApiContext;
