import useLocalStorage from '@rehooks/local-storage';
import Loading from 'components/Loading';
import { LOCAL_STORAGE_PREFIX } from 'config';
import { useSnackbar } from 'notistack';
import Page from 'Page';
import {
  useConfigurationQuery,
  useUpdateInstallationDetails
} from 'providers/api';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IJOPOWER_CONTACT_NUMBER } from 'utils/const';
import InstallationDetailsForm from '../InstallationDetailsForm';
import { InstallationDetailsFormWithTerms } from '../InstallationDetailsForm/InstallationDetailsForm';

function InstallationDetailsPage() {
  const [configurationId] = useLocalStorage<string>(`${LOCAL_STORAGE_PREFIX}_configurationId`);
  const { data: configurationData, isLoading: configurationIsLoading } = useConfigurationQuery(configurationId ?? '', {
    enabled: !!configurationId,
  });
  const updateInstallationDetails = useUpdateInstallationDetails(configurationId ?? '');
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleInstallationDetailsSubmit = async (form: InstallationDetailsFormWithTerms) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { termsAndConditions, ...rest } = form;
    updateInstallationDetails.mutate(rest, {
      onSuccess: () => {
        navigate('/redirect', { replace: true });
      },
      onError: () => {
        enqueueSnackbar(
          `Something went wrong. Please call us on ${IJOPOWER_CONTACT_NUMBER}`,
          { variant: 'error' },
        );
      },
    });
  };

  if (configurationIsLoading) return <Loading />;

  return (
    <Page>
      <InstallationDetailsForm
        configurationData={configurationData}
        onInstallationDetailsSubmit={handleInstallationDetailsSubmit}
      />
    </Page>
  );
}

export default InstallationDetailsPage;
