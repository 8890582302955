/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import CellPanelAllBlackPNG from 'assets/CellPanelAllBlack.png';
import CellPanelBlackFramePNG from 'assets/CellPanelBlackFrame.png';
import CellPanelSilverFramePNG from 'assets/CellPanelSilverFrame.png';
import DeleteIcon from 'assets/DeleteIcon.svg';
import {
  Container, filters, Graphics, Sprite, Texture
} from 'pixi.js';
import {
  PanelFrameType,
  PanelGridRowDto, RoofDto, RoofPanelGridOrientation
} from 'providers/api';
import getDimensions, {
  stageHeight, stageWidth, totalSolarPanelMarginX, totalSolarPanelMarginY
} from './getDimensions';

const handleOnClick = (
  currentIndex: string,
  panelGrid: PanelGridRowDto[],
  deleteIcon: Sprite,
  onUpdatePanelGrid?: (gridRows: PanelGridRowDto[]) => void,

) => {
  deleteIcon.visible = false;
  // 1. Create new array
  const prevSolarPanelArray: PanelGridRowDto[] = Object.values(panelGrid);
  // 2. Find index
  const newArr = prevSolarPanelArray.map(
    (prevRowObject, prevRowIndex) => prevRowObject.slots.map((prevPanel, prevColumnIndex) => {
      // 3. Match index
      const previousIndex = `(${prevRowIndex}-${prevColumnIndex})`;
      if (previousIndex === currentIndex) {
        // 4. return the opposite of current visibility on click, turning hasPanel to true or false
        return prevSolarPanelArray[prevRowIndex].slots[prevColumnIndex] = {
          ...prevSolarPanelArray[prevRowIndex].slots[prevColumnIndex],
          hasPanel:
          !prevSolarPanelArray[prevRowIndex].slots[prevColumnIndex].hasPanel,
        };
      }
      return prevSolarPanelArray[prevRowIndex].slots[prevColumnIndex] = {
        ...prevSolarPanelArray[prevRowIndex].slots[prevColumnIndex],
        hasPanel:
          prevSolarPanelArray[prevRowIndex].slots[prevColumnIndex].hasPanel,
      };
    }),
  );
  //
  // 3. Convert back to Dto so back-end accepts it
  const arrayToObject = newArr.map((array) => ({ slots: [...array] }));
  if (!onUpdatePanelGrid) return;
  onUpdatePanelGrid(arrayToObject);
  panelGrid = arrayToObject;
};

interface CreateSolarPanelsProps {
  currentRoof: RoofDto
  onUpdatePanelGrid?: (gridRows: PanelGridRowDto[]) => void,
  panelGrid: PanelGridRowDto[],
}

function createSolarPanels({
  currentRoof, onUpdatePanelGrid, panelGrid,
}: CreateSolarPanelsProps) {
  const {
    solarPanelArray,
    numberOfPanelsAcross,
    numberOfPanelsDown,
    solarPanelWidth,
    solarPanelHeight,
    panelBaseDimensionX,
    panelBaseDimensionY,
    panelBaseDimensionXWithMargin,
    panelBaseDimensionYWithMargin,
    scaleFactor,
  } = getDimensions(currentRoof);

  // Creating solar panel graphics
  const solarPanelContainer = new Container();
  const solarPanelBaseContainer = new Container();

  solarPanelContainer.sortableChildren = true;
  solarPanelBaseContainer.sortableChildren = true;

  const solarPanelSilverPanelTexture = Texture.from(CellPanelSilverFramePNG);
  const SolarPanelBlackFrameTexture = Texture.from(CellPanelBlackFramePNG);
  const SolarPanelAllBlackTexture = Texture.from(CellPanelAllBlackPNG);

  const getPanelOfType = (frameType: PanelFrameType | undefined) => {
    switch (frameType) {
      case PanelFrameType.SilverFrame:
        return new Sprite(solarPanelSilverPanelTexture);
      case PanelFrameType.BlackFrame:
        return new Sprite(SolarPanelBlackFrameTexture);
      case PanelFrameType.AllBlack:
      default:
        return new Sprite(SolarPanelAllBlackTexture);
    }
  };

  const deleteIconTexture = Texture.from(DeleteIcon);

  const blur = new filters.BlurFilter();

  // Loop through the index of each row and column of the roof grid panel
  solarPanelArray.forEach(
    (rowObject, rowIndex) => {
      const row = rowIndex; // row is the number of objects in the PanelGridArray
      rowObject.slots.forEach((panel, columnIndex) => {
        const column = columnIndex % (numberOfPanelsAcross);
        // e.g. 0000 1111 (4 solar panels on top row, 4 on bottom row).
        const uniquePanelId = `(${rowIndex}-${columnIndex})`;

        const setXPanelWithMargin = (column * panelBaseDimensionX) + (column * totalSolarPanelMarginX);
        const setYPanelWithMargin = (row * panelBaseDimensionY) + (row * totalSolarPanelMarginY);

        const cellPanel = getPanelOfType(currentRoof.panel?.frameType);

        const deleteIcon = new Sprite(deleteIconTexture);

        // PANELS AND ICON
        // Interactivity
        deleteIcon.visible = false;
        cellPanel.interactive = true;
        cellPanel.buttonMode = true;
        cellPanel.on('mouseover', () => { deleteIcon.visible = true; });
        cellPanel.on('mouseout', () => { deleteIcon.visible = false; });
        cellPanel.on('pointerdown', () => {
          handleOnClick(
            uniquePanelId,
            panelGrid,
            deleteIcon,
            onUpdatePanelGrid,
          );
        });
        cellPanel.name = `solarPanel${uniquePanelId}`;

        // Position and dimensions
        deleteIcon.zIndex = 3;
        deleteIcon.anchor.set(0.5, 0.5);
        deleteIcon.x = setXPanelWithMargin + (panelBaseDimensionX / 2);
        deleteIcon.y = setYPanelWithMargin + (panelBaseDimensionY / 2);

        cellPanel.zIndex = 2;
        cellPanel.height = solarPanelHeight;
        cellPanel.width = solarPanelWidth;
        cellPanel.x = setXPanelWithMargin;
        cellPanel.y = setYPanelWithMargin;

        // Orientation
        if (currentRoof.panelOrientation === RoofPanelGridOrientation.Landscape) {
          cellPanel.angle = 90;
          cellPanel.anchor.set(0, 1);
        }
        solarPanelBaseContainer.addChild(cellPanel);
        solarPanelBaseContainer.addChild(deleteIcon);

        // BASE
        const solarPanelBase = new Graphics();

        solarPanelBase
          .beginFill(0x2D2D2D, 0.3)
          .drawRect(
            setXPanelWithMargin,
            setYPanelWithMargin,
            panelBaseDimensionX,
            panelBaseDimensionY,
          )
          .endFill();
        solarPanelBase.name = `base${uniquePanelId}`;
        solarPanelBase.zIndex = 1;

        solarPanelBaseContainer.addChild(solarPanelBase);

        // DROP SHADOW
        const dropShadow = new Graphics();

        dropShadow
          .beginFill(0x2D2D2D, 0.7)
          .drawRect(
            setXPanelWithMargin + 1,
            setYPanelWithMargin + 5,
            panelBaseDimensionX,
            panelBaseDimensionY,
          )
          .endFill();

        dropShadow.filters = [blur];
        dropShadow.name = `dropShadow${uniquePanelId}`;
        dropShadow.zIndex = 0;

        solarPanelBaseContainer.addChild(dropShadow);
      });
    },
  );
  solarPanelContainer.addChild(solarPanelBaseContainer);
  solarPanelContainer.height = (numberOfPanelsDown * panelBaseDimensionYWithMargin) * scaleFactor;
  solarPanelContainer.width = (numberOfPanelsAcross * panelBaseDimensionXWithMargin) * scaleFactor;
  solarPanelContainer.x = (stageWidth - solarPanelContainer.width) / 2;
  solarPanelContainer.y = (stageHeight - solarPanelContainer.height) / 2;

  return { solarPanelContainer, solarPanelBaseContainer, panelGridUpdated: panelGrid };
}

export default createSolarPanels;
