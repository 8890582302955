import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';

function ConfiguratorIsLoading() {
  return (
    <Modal
      open
      sx={{ backgroundColor: 'white', opacity: 1 }}
      BackdropProps={{
        style: { backgroundColor: 'white' },
      }}
    >
      <Box>
        <Box
          sx={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Stack sx={{ alignItems: 'center' }}>
            <Typography variant="h1" sx={{ textAlign: 'center', mb: 2.5 }}>
              Generating configuration
            </Typography>
            <CircularProgress
              size={100}
            />
            <Typography variant="body1" sx={{ textAlign: 'center', mt: 2.5 }}>
              Please wait whilst we find the best configuration for your roof.
            </Typography>
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
}

export default ConfiguratorIsLoading;
