import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import ActionButtons from 'components/ActionButtons';
import SelectionTileList from 'components/SelectionTileList';
import { useFormik } from 'formik';
import { CreateConfigurationCommand, InstallationType, PowerSupplyType } from 'providers/api';
import React from 'react';
import { ConfigSelectionTile } from 'utils/types';
import { mixed, object, SchemaOf } from 'yup';

const customerTypeSchema: SchemaOf<CreateConfigurationCommand> = object().shape({
  installationType: mixed<InstallationType>()
    .required()
    .test((installationTypeValue) => installationTypeValue === InstallationType.Domestic
      || installationTypeValue === InstallationType.Offgrid),
  powerSupplyType: mixed<PowerSupplyType>()
    .required()
    .test((powerSupplyTypeValue) => powerSupplyTypeValue === PowerSupplyType.SinglePhase),
});

interface ICustomerTypeForm extends Partial<CreateConfigurationCommand> {}
interface CustomerTypeFormProps {
  installationAnswerTileArray: ConfigSelectionTile[];
  powerSupplyAnswerTileArray: ConfigSelectionTile[];
  data?: CreateConfigurationCommand;
  onFormSubmit: (form: CreateConfigurationCommand) => void;
}

function CustomerTypeForm({
  installationAnswerTileArray, powerSupplyAnswerTileArray, data, onFormSubmit,
}: CustomerTypeFormProps) {
  const {
    handleSubmit, setFieldValue, values, isValid,
  } = useFormik<ICustomerTypeForm>({
    initialValues: {
      installationType: data?.installationType,
      powerSupplyType: data?.powerSupplyType,
    },
    onSubmit: (form, { setSubmitting }) => {
      setSubmitting(true);
      const createConfigurationCommand = customerTypeSchema.validateSync(form);
      onFormSubmit(createConfigurationCommand);
    },
    validationSchema: customerTypeSchema,
    validateOnMount: true,
  });

  return (
    <form style={{ width: '100%' }} onSubmit={handleSubmit}>
      <Stack direction="column">
        <Typography
          sx={{
            typography: { xs: 'body1', sm: 'h2' },
          }}
          marginBottom={2}
        >
          Welcome to our iJo Power configurator.
          You can design your own solar and energy storage system by
          answering a few questions.
          We will then recommend the best system for you.
          Our ROI calculator will then give you an idea on
          how long you can expect a return on your investment.
        </Typography>
        <Typography
          sx={{
          typography: { xs: 'body1', sm: 'h2' },
        }}
          marginBottom={5}
        >
          If you have already requested an estimate from us, you can
          {' '}
          <Link href="app/existing">click here</Link>
          {' '}
          to review/amend it.
        </Typography>
        <Stack spacing={5}>
          <SelectionTileList
            questionTitle="What type of installation are you looking for?"
            data={installationAnswerTileArray}
            setFieldValue={setFieldValue}
            values={values}
          />
          <SelectionTileList
            questionTitle="What type of power supply do you have?"
            data={powerSupplyAnswerTileArray}
            setFieldValue={setFieldValue}
            values={values}
          />
        </Stack>
      </Stack>
      <ActionButtons
        isValid={isValid}
        hideBackButton
      />
    </form>
  );
}

export default CustomerTypeForm;
