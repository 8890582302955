import { createTheme } from '@mui/material/styles';

export const ijoPowerPrimaryColour = '#3D9272';
const theme = createTheme({
  typography: {
    fontFamily: [
      'Roboto',
      'sans-serif',
    ].join(','),
    h1: {
      fontSize: 36,
      fontWeight: 400,
    },
    h2: {
      fontSize: 20,
      fontWeight: 400,
    },
    h6: {
      fontSize: 22,
      fontWeight: 400,
      color: '#000000',
    },
    body1: {
      color: '#000000',
    },
  },
  palette: {
    background: {
      default: '#FFFFFF',
    },
    primary: {
      main: ijoPowerPrimaryColour,
    },
    secondary: {
      main: '#30353C',
    },
    error: {
      main: '#F44336',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 800,
      lg: 1200,
      xl: 1800,
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginRight: 0,
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        sizeLarge: {
          padding: '11px 22px', // Matches height of icon button
        },
        root:
        {
          textTransform: 'none',
        },
        text:
        {
          color: '#30353C',
        },
        contained: {
          borderRadius: 0,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
      },
    },
  },
});
export default theme;
