/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import ListItem from '@mui/material/ListItem';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FormikProps } from 'formik';
import { PanelBrandDto, PanelDto, PanelStockStatus } from 'providers/api';
import React from 'react';
import getPanelChip from 'utils/getPanelChip';
import { IUpdateRoofPanelForm } from './PanelInfoChangePanel';

interface ChangePanelListProps {
  setChangePanelSelected: (selected: boolean) => void
  brandsData?: PanelBrandDto[]
  uniqueProductsData?: PanelDto[]
  setBrandSelected: (brand: string) => void
  brandSelected: string
  formik: FormikProps<IUpdateRoofPanelForm>
}

function ChangePanelList({
  setChangePanelSelected, brandsData, uniqueProductsData, setBrandSelected, brandSelected, formik,
}: ChangePanelListProps) {
  const {
    setFieldValue, values,
  } = formik;

  const handleSelect = (product: PanelDto) => {
    setFieldValue('panelSku', product.sku);
    setChangePanelSelected(false);
  };

  return (
    <Dialog
      open
      fullWidth
      scroll="paper"
      maxWidth="md"
      PaperProps={{
        sx: {
          maxHeight: 650,
        },
      }}
    >
      <DialogTitle id="solar-panels-list" variant="h1"> Solar Panels </DialogTitle>
      <DialogContent>
        <FormControl
          fullWidth
        >
          <Select
            value={brandSelected ?? ''}
            onChange={(event) => {
              setBrandSelected(event.target.value);
              setFieldValue('panelSku', null);
            }}
            displayEmpty
            fullWidth
            placeholder="Manufacturer"
          >
            <MenuItem
              value=""
              key={0}
            >
              Any
            </MenuItem>
            {
              brandsData && brandsData.map((brand) => (
                <MenuItem
                  value={brand.name}
                  key={brand.name}
                >
                  {brand.name}
                </MenuItem>
              ))
                }
          </Select>
        </FormControl>
        {
        uniqueProductsData
        && uniqueProductsData.length > 0
        ? uniqueProductsData.map((product: PanelDto) => (
          <ListItem
            disableGutters
            key={product.sku}
            sx={{ my: 1 }}
          >
            <Stack width="100%">
              <Stack
                direction="row"
                spacing={1}
                width="100%"
                sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }}
              >
                <Typography variant="h2">
                  {product.product}
                </Typography>
                {
                product.sku === values.panelSku
                  ? (
                    <Button
                      variant="contained"
                      size="small"
                      disabled
                    >
                      Selected
                    </Button>
                  )
                  : (
                    <Button
                      onClick={() => handleSelect(product)}
                      variant="contained"
                      size="small"
                      type="submit"
                      form="change-panel-form"
                      disabled={
                        product.panelStockStatus === PanelStockStatus.OutOfStock
                        || (
                          product.panelStockStatus === PanelStockStatus.DueIn
                          && !product.dueInStockDate
                        )
                      }
                    >
                      Select
                    </Button>
                  )
                }
              </Stack>
              <Stack
                direction="row"
                spacing={1}
                sx={{ alignItems: 'center' }}
              >
                <Typography variant="body1">{product?.price && `£${product?.price.toFixed(2)}`}</Typography>
                { getPanelChip(product) }
              </Stack>
              <Divider sx={{ mt: 1 }} />
            </Stack>
          </ListItem>
        ))
        : <Alert severity="info" sx={{ mt: '20px' }}>No suitable panels available for this brand.</Alert>
        }
      </DialogContent>
      <DialogActions sx={{ pb: 3, pr: 3 }}>
        <Button
          variant="text"
          onClick={() => {
            setChangePanelSelected(false);
            setBrandSelected('');
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ChangePanelList;
