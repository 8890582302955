import ArrowForward from '@mui/icons-material/ArrowForward';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useLocalStorage from '@rehooks/local-storage';
import { LOCAL_STORAGE_PREFIX } from 'config';
import { RoofDto, RoofPanelGridOrientation } from 'providers/api';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import getPanelChip from 'utils/getPanelChip';

interface ConfirmConfigurationsProps {
  listRoofsData: RoofDto[]
  onDeleteRoofConfiguration: (roofId: string) => void
}

function ConfirmConfigurationsForm({ listRoofsData, onDeleteRoofConfiguration }: ConfirmConfigurationsProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentSelectedRoofId, setCurrentSelectedRoofId] = useLocalStorage<string>(
    `${LOCAL_STORAGE_PREFIX}_currentSelectedRoofId`,
  );
  const navigate = useNavigate();
  const [confirmedChoice, setConfirmedChoice] = React.useState(false);

  return (
    <Stack>
      <List
        disablePadding
      >
        {listRoofsData.map((roof: RoofDto) => (
          <ListItem
            disableGutters
            key={roof.roofId}
          >
            <Paper variant="outlined" square sx={{ width: '100%', p: 2 }}>
              <Stack spacing={2}>
                <Typography variant="h2">
                  {roof.name}
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 700 }}>
                  {roof.panel?.product}
                </Typography>
                {
                roof.installedCapacity && roof.installedCapacity !== 0
                && roof.estimatedAnnualOutput && roof.estimatedAnnualOutput !== 0
                && roof?.panelOrientation ? (
                  <Stack spacing={1}>
                    <ul style={{ paddingInlineStart: '20px', listStyleType: 'disc' }}>
                      <li>
                        <Typography variant="body1">
                          Panel orientation:
                          {' '}
                          {RoofPanelGridOrientation[roof.panelOrientation]}
                        </Typography>
                      </li>
                      <li>
                        <Typography component="span" variant="body1">{`Installed Capacity: ${roof.installedCapacity} kW`}</Typography>
                        {' '}
                      </li>
                      <li>
                        {' '}
                        <Typography component="span" variant="body1">{`Estimated Annual Output: ${roof.estimatedAnnualOutput} kWh`}</Typography>
                      </li>
                    </ul>
                  </Stack>
                  )
                  : undefined
                }
                <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                  <Typography variant="body1">
                    &pound;
                    {roof.panel?.price && roof.panel?.price.toFixed(2)}
                  </Typography>
                  <Typography variant="body1" sx={{ color: '#666666' }}>
                    x
                    {' '}
                    {roof.amountOfPanels}
                  </Typography>
                  { getPanelChip(roof.panel) }
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Edit />}
                    onClick={() => {
                      setCurrentSelectedRoofId(roof.roofId ?? '');
                      navigate('/configurator');
                    }}
                  >
                    Edit configuration
                  </Button>
                  {
                  listRoofsData.length > 1
                  && (
                  <Button
                    variant="contained"
                    color="error"
                    startIcon={<Delete />}
                    onClick={() => onDeleteRoofConfiguration(roof.roofId)}
                  >
                    Delete
                  </Button>
                  )
                     }
                </Stack>
              </Stack>
            </Paper>
          </ListItem>
        ))}
      </List>
      <FormControlLabel
        control={(
          <Checkbox
            onClick={() => setConfirmedChoice((previous) => !previous)}
          />
      )}
        label="I am happy with the roof designs specified above"
        sx={{ mt: 2, ml: 1 }}
      />
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          marginTop: 3,
          marginBottom: {
            xs: 20, // Allows space for snackbar confirmation on deleting roof
            lg: 10,
          },
        }}
        justifyContent="flex-end"
      >
        <Button
          endIcon={<ArrowForward />}
          variant="contained"
          onClick={() => navigate('/installation')}
          disabled={!confirmedChoice}
        >
          Next
        </Button>
      </Box>
    </Stack>
  );
}

export default ConfirmConfigurationsForm;
