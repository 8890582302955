/* eslint-disable no-unused-expressions */
import useLocalStorage from '@rehooks/local-storage';
import Loading from 'components/Loading';
import { LOCAL_STORAGE_PREFIX } from 'config';
import Page from 'Page';
import { PowerUsageDto, useConfigurationQuery, useUpdatePowerUsageMutation } from 'providers/api';
import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import PowerUsageForm from '../PowerUsageForm';

export default function PowerUsagePage(): ReactElement {
  const [configurationId] = useLocalStorage<string>(`${LOCAL_STORAGE_PREFIX}_configurationId`);

  const { data: configurationData, isLoading } = useConfigurationQuery(configurationId ?? '', {
    enabled: !!configurationId,
  });
  const updatePowerUsageMutation = useUpdatePowerUsageMutation(configurationId ?? '');

  const navigate = useNavigate();

  const handleSubmit = (data: PowerUsageDto) => {
    updatePowerUsageMutation.mutate(data, {
      onSuccess: () => {
        navigate('/add-roof');
      },
    });
  };

  if (isLoading) return <Loading />;

  return (
    <Page>
      <PowerUsageForm
        data={configurationData?.powerUsage}
        onFormSubmit={handleSubmit}
      />
    </Page>
  );
}
