/* eslint-disable no-unused-expressions */
import Agriculture from '@mui/icons-material/Agriculture';
import Apartment from '@mui/icons-material/Apartment';
import BatteryChargingFull from '@mui/icons-material/BatteryChargingFull';
import Bolt from '@mui/icons-material/Bolt';
import Help from '@mui/icons-material/Help';
import House from '@mui/icons-material/House';
import { Link, SvgIcon } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useLocalStorage } from '@rehooks/local-storage';
import { ReactComponent as ThreeBolts } from 'assets/ThreeBolts.svg';
import OptionDialog from 'components/OptionDialog';
import { LOCAL_STORAGE_PREFIX } from 'config';
import Page from 'Page';
import {
  CreateConfigurationCommand,
  InstallationType, PowerSupplyType,
  useCreateConfiguration
} from 'providers/api';
import React, { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IJOPOWER_CALENDY_CALL_LINK, selectionTileIconSize } from 'utils/const';
import { ConfigSelectionTile } from 'utils/types';
import CustomerTypeForm from '../CustomerTypeForm';

export default function CustomerTypePage(): ReactElement {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [configurationId, setConfigurationId] = useLocalStorage<string>(`${LOCAL_STORAGE_PREFIX}_configurationId`);
  const [invalidInstallationSelected, setInvalidInstallationSelected] = useState(false);
  const [dontKnowPowerSupplySelected, setDontKnowPowerSupplySelected] = useState(false);
  const createConfigurationMutation = useCreateConfiguration();
  const navigate = useNavigate();

  const installationAnswerTileArray: ConfigSelectionTile[] = [
    {
      id: 1,
      label: 'Domestic',
      field: 'installationType',
      value: InstallationType.Domestic,
      buttonIcon: <House
        color="primary"
        sx={{
          textAlign: 'center',
          fontSize: selectionTileIconSize,
        }}
      />,
    },
    {
      id: 2,
      label: 'Commercial',
      field: 'installationType',
      value: InstallationType.Commercial,
      buttonIcon: <Apartment
        color="primary"
        sx={{
          textAlign: 'center',
          fontSize: selectionTileIconSize,
        }}
      />,
      buttonAction: () => setInvalidInstallationSelected((prev) => !prev),
    },
    {
      id: 3,
      label: 'Off-grid',
      field: 'installationType',
      value: InstallationType.Offgrid,
      buttonIcon: <BatteryChargingFull
        color="primary"
        sx={{
          textAlign: 'center',
          fontSize: selectionTileIconSize,
        }}
      />,
    },
    {
      id: 4,
      label: 'Agricultural',
      field: 'installationType',
      value: InstallationType.Agricultural,
      buttonIcon: <Agriculture
        color="primary"
        sx={{
          textAlign: 'center',
          fontSize: selectionTileIconSize,
        }}
      />,
      buttonAction: () => setInvalidInstallationSelected((prev) => !prev),
    },
  ];

  const powerSupplyAnswerTileArray: ConfigSelectionTile[] = [
    {
      id: 1,
      label: 'Single Phase',
      field: 'powerSupplyType',
      value: PowerSupplyType.SinglePhase,
      buttonIcon: <Bolt
        color="primary"
        sx={{
          textAlign: 'center',
          fontSize: selectionTileIconSize,
        }}
      />,
    },
    {
      id: 2,
      label: 'Three Phase',
      field: 'powerSupplyType',
      value: PowerSupplyType.ThreePhase,
      buttonIcon: <SvgIcon
        component={ThreeBolts}
        inheritViewBox
        color="primary"
        sx={{
          textAlign: 'center',
          fontSize: selectionTileIconSize,
        }}
      />,
      buttonAction: () => setInvalidInstallationSelected((prev) => !prev),
    },
    {
      id: 3,
      label: 'Don\'t know',
      field: 'powerSupplyType',
      value: PowerSupplyType.DontKnow,
      buttonIcon: <Help
        color="primary"
        sx={{
          textAlign: 'center',
          fontSize: selectionTileIconSize,
        }}
      />,
      buttonAction: () => setDontKnowPowerSupplySelected((prev) => !prev),
    },
    {
      id: 4,
      label: '',
      field: '',
      value: undefined,
      buttonIcon: undefined,
      hidden: true,
    },
  ];

  const handleSubmit = (data: CreateConfigurationCommand) => {
      createConfigurationMutation.mutate(data, {
        onSuccess: (response) => {
          setConfigurationId(response.entityId);
          navigate('/new/power-usage');
        },
      });
  };

  return (
    <Page>
      <CustomerTypeForm
        installationAnswerTileArray={installationAnswerTileArray}
        powerSupplyAnswerTileArray={powerSupplyAnswerTileArray}
        onFormSubmit={handleSubmit}
      />
      {
        invalidInstallationSelected
        && (
          <OptionDialog
            title="Contact Us"
            description={(
              <Typography variant="body1" component="span">
                Due to the unique nature of Agricultural and Commercial (three phase) projects,
                our configurator cannot provide a quote.
                <br />
                <br />
                However, we will be able to help you,
                so please
                {' '}
                <Link underline="none" href={IJOPOWER_CALENDY_CALL_LINK}>click here</Link>
                {' '}
                to book a free 15 minute consultation with an expert to discuss your project.
              </Typography>
            )}
            optionBButtonText="Close"
            onOptionB={() => {
              invalidInstallationSelected && setInvalidInstallationSelected(false);
            }}
            showCancelButton={false}
          />
        )
      }
      {
        dontKnowPowerSupplySelected
        && (
          <OptionDialog
            title="Need Help?"
            description={(
              <Typography variant="body1" component="span">
                In most cases, domestic homes are on a single phase supply
                and commercial, industrial and agricultural buildings have a three-phase supply.
              </Typography>
            )}
            optionBButtonText="Close"
            onOptionB={() => {
              dontKnowPowerSupplySelected && setDontKnowPowerSupplySelected(false);
            }}
            showCancelButton={false}
          />
        )
      }
    </Page>
  );
}
