function getMonthName(monthNumber: string) {
  const date = new Date();
  date.setMonth(Number(monthNumber) - 1);

  return date.toLocaleString('en-GB', { month: 'short' });
}

// eslint-disable-next-line import/prefer-default-export
export const dateStringConversion = (dateAsString: string) => {
  const removeTime = dateAsString.split('T')[0];
  const splitYearsMonthsDays = removeTime.split('-');
  const reverseToDayMonthYear = splitYearsMonthsDays.reverse();
  const monthNumberToName = [
    reverseToDayMonthYear[0],
    getMonthName(reverseToDayMonthYear[1]),
    reverseToDayMonthYear[2],
  ];
  const convertToString = monthNumberToName.toString();
  return convertToString.replaceAll(',', ' ');
};
