import { Box, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import iJoPowerLogo from 'assets/Logo2022.jpg';
import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { IJOPOWER_CONTACT_NUMBER } from 'utils/const';

export default function TopBar(): ReactElement {
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const navigate = useNavigate();

  return (
    <>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px',
        gap: '10px',
        background: theme.palette.primary.main,
      }}
      >
        <Typography
          variant="body1"
          color="#FFFF"
          textAlign="center"
          padding={0.5}
        >
          To speak to a solar expert today,
          {' '}
          {!mdUp && <br />}
          call us on
          {' '}
          {IJOPOWER_CONTACT_NUMBER}
        </Typography>
      </Box>
      <Box sx={{
        display: 'flex',
        justifyContent: { xs: 'center', sm: 'center', md: 'flex-start' },
      }}
      >
        <Box
          component="img"
          sx={{
            width: 205,
            cursor: 'pointer',
          }}
          alt="Ijo Power Logo"
          src={iJoPowerLogo}
          margin={2}
          onClick={() => navigate('/')}
        />
      </Box>
    </>
  );
}
