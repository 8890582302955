/* eslint-disable max-len */
import useLocalStorage from '@rehooks/local-storage';
import {
  BAD_REQUEST_CODE,
  EXPECTED_ERROR_CODES,
  FORBIDDEN_ERROR_CODE,
  LOCAL_STORAGE_PREFIX,
  SECOND_IN_MILLISECONDS
} from 'config';
import { useSnackbar } from 'notistack';
import React from 'react';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider
} from 'react-query';
import { IJOPOWER_CONTACT_NUMBER } from 'utils/const';
import { ApiException } from './api';
import ErrorSnackMessage from './ErrorSnackMessage';

const GENERIC_ERROR_TITLE = 'Sorry about this!';
const GENERIC_ERROR_DETAILS = `Something  appears to have gone wrong. Please call us on ${IJOPOWER_CONTACT_NUMBER}`;

// Error string format '[2].volume:[ "Input string '6500.04' is not a valid integer. Path '[2].volume', line 1, position 350." ]'
const processErrors = (errors: { [id: string]: string[] }) => Object.values(errors)
  .map((messages) => messages.map((errorMsg) => errorMsg.split('Path')[0]).join(', '))
  .slice(0, 10);

// eslint-disable-next-line func-names
// eslint-disable-next-line import/prefer-default-export
export const withQueryClientDefaults = (WrappedComponent: React.ComponentType) => function () {
  const { enqueueSnackbar } = useSnackbar();
  const [configurationId] = useLocalStorage<string>(`${LOCAL_STORAGE_PREFIX}_configurationId`);

  const triggerErrorFeedback = (error: any) => {
    let errorTitle = GENERIC_ERROR_TITLE;
    let errorDetails = [GENERIC_ERROR_DETAILS];

    const isExpectedError = error instanceof ApiException && EXPECTED_ERROR_CODES.includes(error.status);

    if (isExpectedError && error.result) {
      if (error.result.title) errorTitle = error.result.title;
      switch (error.status) {
        case BAD_REQUEST_CODE:
          if (error.result?.errors) errorDetails = processErrors(error.result.errors.results);
          break;
        // INFO: The server returns error info in header. Could be improved server side before processing here.
        case FORBIDDEN_ERROR_CODE:
          errorTitle = 'Insufficient Access';
          errorDetails = ['You do not have permissions to perform the requested action.'];
          break;
        default:
          if (error.result && error.result.detail) errorDetails = [error.result.detail];
      }
    }

    enqueueSnackbar(errorDetails, {
      variant: 'error',
      persist: !isExpectedError,
      autoHideDuration: SECOND_IN_MILLISECONDS * 30,
      preventDuplicate: true,
      key: `CODE:${error.status}`,
      // eslint-disable-next-line react/no-unstable-nested-components
      content: (key) => (
        configurationId && <ErrorSnackMessage key={key} details={errorDetails} title={errorTitle} id={key} />
      ),
    });
  };

  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: triggerErrorFeedback,
    }),
    mutationCache: new MutationCache({
      onError: triggerErrorFeedback,
    }),
    // Turning off refetch on window focus wasn't working on ListRoofData in ConfiguratorPage.
    // applied and worked at global level. Please leave unless better solution.
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      <WrappedComponent />
    </QueryClientProvider>
  );
};
