import axios from 'axios';
import React, { ReactNode } from 'react';
import { ConfigurationsService, PanelsService, RoofsService } from './api';
import ApiContext, { IApiContext } from './context';

export interface ApiProviderProps {
  children: ReactNode;
}

function ApiProvider({ children }: ApiProviderProps) {
  const value: IApiContext = React.useMemo(() => {
    const instance = axios.create({
      transformResponse: (res) => res,
    });

    const configurations = new ConfigurationsService(window.location.origin, instance);
    const roofs = new RoofsService(window.location.origin, instance);
    const panels = new PanelsService(window.location.origin, instance);

    return ({
      configurations,
      roofs,
      panels,
    });
  }, []);

  return (
    <ApiContext.Provider value={value}>
      {children}
    </ApiContext.Provider>
  );
}

export default ApiProvider;
