import ChangePanelList from './ChangePanelList';
import ConfiguratorForm from './ConfiguratorForm';
import PanelInfoChangePanel from './PanelInfoChangePanel';
import PanelOrientation from './PanelOrientation';
import RoofSelection from './RoofSelection';

export default ConfiguratorForm;

export {
  RoofSelection, PanelOrientation, PanelInfoChangePanel as PanelInformation, ChangePanelList,
};
