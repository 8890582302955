import useLocalStorage from '@rehooks/local-storage';
import { LOCAL_STORAGE_PREFIX } from 'config';
import { useSnackbar } from 'notistack';
import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

export default function ProtectedRoute({ children }: { children: ReactElement }) {
  const { enqueueSnackbar } = useSnackbar();
  const [configurationId] = useLocalStorage<string>(`${LOCAL_STORAGE_PREFIX}_configurationId`);
  if (!configurationId) {
    setTimeout(() => {
      enqueueSnackbar('We couldn\'t find your details, please select an option to continue.', { variant: 'error' });
    }, 2000);
    return <Navigate to="/" />;
  }
  return children;
}
