import Typography from '@mui/material/Typography';
import useLocalStorage, { deleteFromStorage } from '@rehooks/local-storage';
import { LOCAL_STORAGE_PREFIX } from 'config';
import Page from 'Page';
import { ConfigurationForm, useGetExistingConfiguration } from 'providers/api';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import RetrieveConfigurationForm from '../RetrieveConfigurationForm';

function RetrieveConfigurationPage() {
  const navigate = useNavigate();
  const getExistingConfiguration = useGetExistingConfiguration();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [configurationId, setConfigurationId] = useLocalStorage<string>(`${LOCAL_STORAGE_PREFIX}_configurationId`);

  const handleGetExistingConfigurationSubmit = async (form: ConfigurationForm) => {
    deleteFromStorage(`${LOCAL_STORAGE_PREFIX}_configurationId`);
    deleteFromStorage(`${LOCAL_STORAGE_PREFIX}_currentSelectedRoofId`);
    getExistingConfiguration.mutate(form, {
      onSuccess: (response) => {
        setConfigurationId(response.entityId);
        navigate('/configurator', { replace: true });
      },
    });
  };

  return (
    <Page middleAlignOnMobile="center">
      <Typography sx={{
        typography: { xs: 'h6', sm: 'h1' },
        mb: { xs: 2, sm: 4 },
      }}
      >
        Retrieve Configuration
      </Typography>
      <RetrieveConfigurationForm
        onGetExistingConfigurationSubmit={handleGetExistingConfigurationSubmit}
      />
    </Page>
  );
}

export default RetrieveConfigurationPage;
