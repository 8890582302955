import Alert from '@mui/material/Alert';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ActionButtons from 'components/ActionButtons';
import { useFormik } from 'formik';
import { ConfigurationDto, ProjectGoAhead, UpdateInstallationDetailsForm } from 'providers/api';
import React from 'react';
import { Nullable } from 'utils/types';
import {
  boolean, mixed, object, SchemaOf, string
} from 'yup';

export type InstallationDetailsFormWithTerms = UpdateInstallationDetailsForm & {
  termsAndConditions: boolean
}

const postcodeRegex = /^([A-Z][A-HJ-Y]?\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/;

const installationDetailsSchema: SchemaOf<InstallationDetailsFormWithTerms> = object({
  projectGoAhead: mixed<ProjectGoAhead>()
    .required('Please select when you would like your project to go ahead')
    .test((value) => Object.values(ProjectGoAhead).includes(value as ProjectGoAhead)),
  installer: boolean().required('Please select an option'),
  email: string().email('Please enter a valid email').required('Please enter your email address'),
  postcode: string()
    .required('Please enter your postcode')
    .uppercase()
    .matches(
      postcodeRegex,
      'Please enter a valid UK postcode',
    ),
  firstName: string().required('Please enter your first name'),
  lastName: string().required('Please enter your last name'),
  termsAndConditions: boolean().required().test(
    'boolean',
    'Please accept our terms and conditions to proceed',
    (value) => value === true,
  ),
});

type IInstallationDetailsFormWithTerms = Nullable<InstallationDetailsFormWithTerms>
interface InstallationDetailsProps {
  configurationData?: ConfigurationDto
  onInstallationDetailsSubmit: (form: InstallationDetailsFormWithTerms) => void
}

function InstallationDetailsForm({ configurationData, onInstallationDetailsSubmit }: InstallationDetailsProps) {
  const {
    handleSubmit, handleChange, handleBlur, values, isValid, touched, errors,
  } = useFormik<IInstallationDetailsFormWithTerms>({
    initialValues: {
      projectGoAhead: configurationData?.installationDetails?.projectGoAhead ?? null,
      installer: configurationData?.installationDetails?.installer ?? null,
      email: configurationData?.email ?? '',
      postcode: configurationData?.postcode ?? '',
      firstName: configurationData?.firstName ?? '',
      lastName: configurationData?.lastName ?? '',
      termsAndConditions: false,
    },
    onSubmit: (form, { setSubmitting }) => {
      setSubmitting(true);
      const installationDetailsCommand = installationDetailsSchema.validateSync(form);
      onInstallationDetailsSubmit(installationDetailsCommand);
    },
    validationSchema: installationDetailsSchema,
    validateOnMount: true,
  });

  return (
    <form style={{ width: '100%' }} onSubmit={handleSubmit}>
      <Typography
        sx={{
          typography: { xs: 'h6', sm: 'h1' },
          mb: { xs: 2, sm: 4 },
        }}
      >
        Installation details
      </Typography>
      <Stack direction="column" spacing={2}>
        <Stack spacing={1}>
          <Typography
            sx={{
              typography: { xs: 'body1', sm: 'h2' },
              mb: 1,
            }}
          >
            When would you like your project to go ahead?
          </Typography>
          <Select
            value={values.projectGoAhead ?? ''}
            onChange={handleChange}
            onBlur={handleBlur}
            name="projectGoAhead"
          >
            <MenuItem value={10}>As soon as possible</MenuItem>
            <MenuItem value={20}>Within 6 months</MenuItem>
            <MenuItem value={30}>Within 12 months</MenuItem>
            <MenuItem value={40}>Just looking at my options</MenuItem>
          </Select>
        </Stack>
        <Stack spacing={1}>
          <Typography
            sx={{
              typography: { xs: 'body1', sm: 'h2' },
              mb: 1,
            }}
          >
            Are you looking for an installer or do you have your own tradespeople?
          </Typography>
          <Select
            value={values.installer ?? ''}
            name="installer"
            onChange={handleChange}
          >
            <MenuItem value={true as any}>I am looking for an installer</MenuItem>
            <MenuItem value={false as any}>I have my own tradespeople</MenuItem>
          </Select>
        </Stack>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} width="100%">
          <Stack spacing={1} width="100%">
            <Typography
              sx={{
                typography: { xs: 'body1', sm: 'h2' },
                mb: 1,
              }}
            >
              First name
            </Typography>
            <TextField
              name="firstName"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.firstName ?? ''}
              error={touched.firstName && Boolean(errors.firstName)}
              helperText={touched.firstName as boolean && errors.firstName as string}
            />
          </Stack>
          <Stack spacing={1} width="100%">
            <Typography
              sx={{
                typography: { xs: 'body1', sm: 'h2' },
                mb: 1,
              }}
            >
              Last name
            </Typography>
            <TextField
              name="lastName"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.lastName ?? ''}
              error={touched.lastName && Boolean(errors.lastName)}
              helperText={touched.lastName as boolean && errors.lastName as string}
            />
          </Stack>
        </Stack>
        <Alert
          severity="info"
        >
          Your email information will be kept expressly for the purposes of
          providing you with a quotation.

          At iJo Power, we are here to help and assist in your decision making
          and we do not undertake in any cold-calling or unsolicited contact.
        </Alert>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} width="100%">
          <Stack spacing={1} width="100%">
            <Typography
              sx={{
                typography: { xs: 'body1', sm: 'h2' },
                mb: 1,
              }}
            >
              Email Address
            </Typography>
            <TextField
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
              name="email"
              fullWidth
            />
          </Stack>
          <Stack spacing={1} width="100%">
            <Typography
              sx={{
                typography: { xs: 'body1', sm: 'h2' },
                mb: 1,
              }}
            >
              Postcode
            </Typography>
            <TextField
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.postcode && values.postcode.toUpperCase()}
              error={touched.postcode && Boolean(errors.postcode)}
              helperText={touched.postcode && errors.postcode}
              name="postcode"
              fullWidth
            />
          </Stack>
        </Stack>
        <Stack spacing={1}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={values.termsAndConditions ?? false} />}
              name="termsAndConditions"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.termsAndConditions}
              label={(
                <Typography>
                  Agree to our
                  {' '}
                  <Link
                    underline="none"
                    href="https://ijopower.com/pages/terms-and-conditons"
                    target="_blank"
                  >
                    terms and conditions
                  </Link>
                </Typography>
                    )}
            />
          </FormGroup>
        </Stack>
      </Stack>
      <ActionButtons
        isValid={isValid}
        backPath="/confirm-configurations"
        submitText="Submit"
      />
    </form>
  );
}

export default InstallationDetailsForm;
