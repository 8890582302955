/* eslint-disable no-unused-expressions */
import GrassIcon from '@mui/icons-material/Grass';
import Help from '@mui/icons-material/Help';
import HomeIcon from '@mui/icons-material/Home';
import RemoveIcon from '@mui/icons-material/Remove';
import RoofingIcon from '@mui/icons-material/Roofing';
import WavesIcon from '@mui/icons-material/Waves';
import Link from '@mui/material/Link';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import useLocalStorage from '@rehooks/local-storage';
import Loading from 'components/Loading';
import OptionDialog from 'components/OptionDialog';
import { LOCAL_STORAGE_PREFIX } from 'config';
import Page from 'Page';
import { MountType, RoofType, UpdateRoofForm } from 'providers/api';
import { useGetRoofQuery, useUpdateRoofMutation } from 'providers/api/useRoof';
import React, { ReactElement } from 'react';
import { CgShapeZigzag } from 'react-icons/cg';
import { useNavigate, useParams } from 'react-router-dom';
import { IJOPOWER_CALENDY_CALL_LINK, IJOPOWER_EMAIL_ADDRESS, selectionTileIconSize } from 'utils/const';
import { ConfigSelectionTile } from 'utils/types';
import EditRoofForm from '../EditRoofForm';

export default function EditRoofPage(): ReactElement {
  const [configurationId] = useLocalStorage<string>(`${LOCAL_STORAGE_PREFIX}_configurationId`);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentSelectedRoofId, setCurrentSelectedRoofId] = useLocalStorage<string>(
    `${LOCAL_STORAGE_PREFIX}_currentSelectedRoofId`,
  );
  const params = useParams<'roofId'>();
  const { data: roofData, isLoading: roofIsLoading } = useGetRoofQuery({
    configurationId: configurationId ?? null,
    roofId: params.roofId ?? '',
  }, {
    enabled: !!params.roofId,
  });
  const updateRoofMutation = useUpdateRoofMutation(configurationId ?? '');
  const navigate = useNavigate();
  const [dontKnowMountSelected, setDontKnowMountSelected] = React.useState(false);
  const [flatRoofSelected, setFlatRoofSelected] = React.useState(false);
  const [groundMountingSelected, setGroundMountingSelected] = React.useState(false);
  const [inRoofandCorrugatedSelected, setInRoofandCorrugatedSelected] = React.useState(false);

  const mountTypeTileArray: ConfigSelectionTile[] = [
    {
      id: 1,
      label: 'On roof',
      field: 'mountType',
      value: MountType.OnRoof,
      buttonIcon: <HomeIcon
        color="primary"
        sx={{
          textAlign: 'center',
          fontSize: selectionTileIconSize,
        }}
      />,
    },
    {
      id: 2,
      label: 'In roof',
      field: 'mountType',
      value: MountType.InRoof,
      buttonIcon: <RoofingIcon
        color="primary"
        sx={{
          textAlign: 'center',
          fontSize: selectionTileIconSize,
        }}
      />,
    },
    {
      id: 3,
      label: 'Ground mounting',
      field: 'mountType',
      value: MountType.GroundMounting,
      buttonIcon: <GrassIcon
        color="primary"
        sx={{
          textAlign: 'center',
          fontSize: selectionTileIconSize,
        }}
      />,
      buttonAction: () => setGroundMountingSelected(true),
    },
    {
      id: 4,
      label: 'Don\'t know',
      field: 'mountType',
      value: MountType.DontKnow,
      buttonIcon: <Help
        color="primary"
        sx={{
          textAlign: 'center',
          fontSize: selectionTileIconSize,
        }}
      />,
      buttonAction: () => setDontKnowMountSelected(true),
    },
  ];

  const roofTypeTileArray: ConfigSelectionTile[] = [
    {
      id: 1,
      label: 'Tile',
      field: 'roofType',
      value: RoofType.Tile,
      buttonIcon: <WavesIcon
        color="primary"
        sx={{
          textAlign: 'center',
          fontSize: selectionTileIconSize,
        }}
      />,
    },
    {
      id: 2,
      label: 'Slate',
      field: 'roofType',
      value: RoofType.Slate,
      buttonIcon: <RoofingIcon
        color="primary"
        sx={{
          textAlign: 'center',
          fontSize: selectionTileIconSize,
        }}
      />,
    },
    {
      id: 3,
      label: 'Flat',
      field: 'roofType',
      value: RoofType.Flat,
      buttonIcon: <RemoveIcon
        color="primary"
        sx={{
          textAlign: 'center',
          fontSize: selectionTileIconSize,
        }}
      />,
      buttonAction: () => setFlatRoofSelected(true),
    },
    {
      id: 4,
      label: 'Corrugated',
      field: 'roofType',
      value: RoofType.Corrugated,
      buttonIcon: <SvgIcon
        component={CgShapeZigzag}
        inheritViewBox
        color="primary"
        sx={{
          textAlign: 'center',
          fontSize: selectionTileIconSize,
          transform: 'rotate(180deg)',
        }}
      />,
    },
  ];

  const handleSubmit = (data: UpdateRoofForm) => {
    const updatePayload = {
      roofId: params.roofId ?? '',
      command: { updateRoofForm: data },
    };
    updateRoofMutation.mutate(updatePayload, {
      onSuccess: () => {
        setCurrentSelectedRoofId(params.roofId ?? '');
        navigate('/configurator');
      },
    });
  };

  if (roofIsLoading) return <Loading />;

  return (
    <Page>
      <EditRoofForm
        roofData={roofData}
        onFormSubmit={handleSubmit}
        mountTypeTileArray={mountTypeTileArray}
        roofTypeTileArray={roofTypeTileArray}
        setInRoofandCorrugatedSelected={setInRoofandCorrugatedSelected}
      />
      {
        groundMountingSelected
        && (
          <OptionDialog
            title="Contact Us"
            description={(
              <Typography variant="body1" component="span">
                Our configurator is only currently designed for roof mounted panels.
                <br />
                <br />
                For a ground mounted solution, we recommend that you
                {' '}
                <Link underline="none" href={`mailto:${IJOPOWER_EMAIL_ADDRESS}`}>email us</Link>
                {' '}
                or book in a call to discuss your requirements.
                <br />
                <br />
                {' '}
                <Link underline="none" href={IJOPOWER_CALENDY_CALL_LINK}>Click here</Link>
                {' '}
                to book a free 15 minute consultation with an expert to discuss your project.
              </Typography>
            )}
            optionBButtonText="Close"
            onOptionB={() => {
              groundMountingSelected && setGroundMountingSelected(false);
            }}
            showCancelButton={false}
          />
        )
      }
      {
        dontKnowMountSelected
        && (
          <OptionDialog
            title="Need help?"
            description={(
              <Typography variant="body1" component="span">
                On roof is the typical roof mounting solution for domestic houses.
                <br />
                <br />
                For new builds, we would recommend looking at an In roof solution.
                <br />
                <br />
                If you have space and you do not want to mount panels on a roof,
                you could consider a Ground mounting solution.
              </Typography>
            )}
            optionBButtonText="Close"
            onOptionB={() => {
              dontKnowMountSelected && setDontKnowMountSelected(false);
            }}
            showCancelButton={false}
          />
        )
      }
      {
        flatRoofSelected
        && (
          <OptionDialog
            title="Contact Us"
            description={(
              <Typography variant="body1" component="span">
                Our configurator is only currently designed for tile, slate or corrugated roofs.
                <br />
                <br />
                For flat roofs, we recommend that you
                {' '}
                <Link underline="none" href={`mailto:${IJOPOWER_EMAIL_ADDRESS}`}>email us</Link>
                {' '}
                or book in a call to discuss your requirements.
                <br />
                <br />
                {' '}
                <Link underline="none" href={IJOPOWER_CALENDY_CALL_LINK}>Click here</Link>
                {' '}
                to book a free 15 minute consultation with an expert to discuss your project.
              </Typography>
            )}
            optionBButtonText="Close"
            onOptionB={() => {
              flatRoofSelected && setFlatRoofSelected(false);
            }}
            showCancelButton={false}
          />
        )
      }
      { inRoofandCorrugatedSelected
        && (
          <OptionDialog
            title="Contact us"
            description={(
              <Typography variant="body1" component="span">
                We currently can only work on corrugated roofs with an on roof mount.
                <br />
                <br />
                Please
                {' '}
                <Link underline="none" href={`mailto:${IJOPOWER_EMAIL_ADDRESS}`}>email us</Link>
                {' '}
                or book in a call to discuss your requirements.
                <br />
                <br />
                {' '}
                <Link underline="none" href={IJOPOWER_CALENDY_CALL_LINK}>Click here</Link>
                {' '}
                to book a free 15 minute consultation with an expert to discuss your project.
              </Typography>
            )}
            optionBButtonText="Close"
            onOptionB={() => {
              setInRoofandCorrugatedSelected(false);
            }}
            showCancelButton={false}
          />
        )}
    </Page>
  );
}
